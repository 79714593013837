import React from "react";

export default function URLIcon({ color = "#F8F8F8" }) {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.0196 1.979C23.013 1.97259 23.0065 1.96619 22.9998 1.95991C20.6049 -0.417122 16.7876 -0.662398 14.1102 1.3908C13.8422 1.59571 13.6263 1.78204 13.4307 1.97708C13.4251 1.98272 13.4196 1.98836 13.4141 1.99412L9.12915 6.27954C8.7538 6.65489 8.7538 7.26347 9.12928 7.63869C9.50475 8.01416 10.1133 8.01404 10.4884 7.63856L14.7892 3.33712C14.7937 3.33264 14.7981 3.32815 14.8025 3.32367C14.9336 3.19424 15.0851 3.06481 15.2786 2.91692C17.2019 1.44219 19.9446 1.62288 21.659 3.33738C21.6647 3.34302 21.6704 3.34866 21.6762 3.35417C23.5538 5.24897 23.5486 8.3189 21.6603 10.2079L17.3598 14.509C16.9844 14.8843 16.9844 15.4929 17.3599 15.8681C17.5476 16.0557 17.7935 16.1496 18.0395 16.1496C18.2854 16.1496 18.5314 16.0558 18.7192 15.868L23.0198 11.567C25.6625 8.92312 25.6625 4.62194 23.0196 1.979Z"
        fill={color}
      />
      <path
        d="M14.5103 17.3543L10.2121 21.6526C10.2036 21.6612 10.1952 21.6701 10.187 21.6789C9.71181 22.1464 9.15424 22.5053 8.5281 22.7468C6.73466 23.4411 4.69799 23.012 3.34052 21.6544C1.44802 19.7599 1.44802 16.6771 3.34052 14.7832L7.6408 10.4846C7.82098 10.3043 7.92234 10.0598 7.92234 9.80479V9.80312C7.92234 9.2722 7.49215 8.84277 6.96123 8.84277C6.68763 8.84277 6.44069 8.95747 6.26564 9.1411L1.98099 13.4241C-0.660158 16.0675 -0.660414 20.3691 1.98073 23.0132C3.27439 24.3071 5.00863 24.9968 6.7749 24.9968C7.59685 24.9967 8.42597 24.8473 9.22049 24.5398C10.1075 24.1981 10.8977 23.6851 11.5689 23.0154C11.5787 23.0058 11.5882 22.9958 11.5975 22.9856L15.8696 18.7136C16.245 18.3381 16.245 17.7296 15.8695 17.3543C15.4944 16.9791 14.8858 16.9791 14.5103 17.3543Z"
        fill={color}
      />
      <path
        d="M7.58031 17.4147C7.76804 17.6024 8.01396 17.6962 8.26001 17.6962C8.50592 17.6962 8.75197 17.6024 8.93958 17.4148L17.4184 8.93637C17.7939 8.56103 17.7939 7.95245 17.4185 7.57723C17.0432 7.20175 16.4346 7.20188 16.0594 7.5771L7.58056 16.0555C7.20496 16.4308 7.20496 17.0393 7.58031 17.4147Z"
        fill={color}
      />
    </svg>
  );
}
