import React from "react";

export default function GearsMoveIcon() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7088 11.8511H18.3717V13.1139H11.7088V11.8511ZM11.7088 14.3768H18.3717V15.6397H11.7088V14.3768ZM11.7088 16.9026H18.3717V18.1655H11.7088V16.9026ZM9.04365 11.8511C8.69146 11.8586 8.3413 11.7985 8.01433 11.6743C7.68735 11.55 7.39034 11.3643 7.14124 11.1282C6.89215 10.8921 6.69613 10.6106 6.56503 10.3008C6.43393 9.99089 6.37047 9.65905 6.37848 9.32528C6.37047 8.99151 6.43393 8.65967 6.56503 8.34979C6.69613 8.03992 6.89215 7.75845 7.14124 7.52237C7.39034 7.2863 7.68735 7.10054 8.01433 6.97629C8.3413 6.85205 8.69146 6.79191 9.04365 6.79951C9.39584 6.79191 9.746 6.85205 10.073 6.97629C10.3999 7.10054 10.697 7.2863 10.9461 7.52237C11.1952 7.75845 11.3912 8.03992 11.5223 8.34979C11.6534 8.65967 11.7168 8.99151 11.7088 9.32528H13.0414C13.0414 8.57595 12.8069 7.84346 12.3677 7.22041C11.9284 6.59737 11.304 6.11177 10.5735 5.82502C9.84303 5.53826 9.03922 5.46323 8.26373 5.60942C7.48824 5.75561 6.77591 6.11644 6.21681 6.64629C5.65772 7.17615 5.27697 7.85122 5.12272 8.58615C4.96846 9.32108 5.04763 10.0828 5.35021 10.7751C5.65279 11.4674 6.16519 12.0591 6.82262 12.4754C7.48005 12.8917 8.25297 13.1139 9.04365 13.1139V11.8511Z"
        fill="white"
      />
      <path
        d="M17.9082 6.19612L16.3358 3.61478C16.1819 3.36157 15.9401 3.16681 15.6517 3.06374C15.3632 2.96068 15.0459 2.95569 14.754 3.04964L13.1322 3.56995C12.8527 3.39162 12.5607 3.2317 12.2581 3.09132L11.9222 1.50008C11.8617 1.21391 11.6985 0.956433 11.4603 0.771439C11.2221 0.586445 10.9236 0.485353 10.6156 0.485352H7.47075C7.16269 0.485382 6.86415 0.586564 6.62593 0.771684C6.38771 0.956803 6.22453 1.21442 6.16415 1.50071L5.82834 3.09068C5.52231 3.22974 5.22687 3.38882 4.94417 3.56679L3.33175 3.04964C3.04 2.95602 2.72302 2.96118 2.43483 3.06423C2.14664 3.16728 1.90507 3.36185 1.7513 3.61478L0.178853 6.19612C0.0248137 6.44888 -0.0320815 6.74441 0.0178438 7.03244C0.0677692 7.32047 0.221432 7.58321 0.452699 7.77599L1.73931 8.84755C1.72798 9.00667 1.71399 9.16453 1.71399 9.32555C1.71399 9.48846 1.72065 9.64948 1.73265 9.80924L0.453365 10.8751C0.221883 11.0677 0.0679641 11.3304 0.0177944 11.6184C-0.0323753 11.9065 0.0243029 12.2021 0.178187 12.455L1.75064 15.0363C1.90446 15.2895 2.14625 15.4843 2.43473 15.5874C2.7232 15.6904 3.04049 15.6954 3.33241 15.6015L4.95417 15.0812C5.23355 15.2598 5.52564 15.4199 5.82834 15.5604L6.16349 17.1504C6.22389 17.4368 6.38717 17.6945 6.62552 17.8796C6.86388 18.0648 7.16257 18.1659 7.47075 18.1658H9.0432V16.9029H7.47075L6.99768 14.6612C6.34327 14.4309 5.73578 14.095 5.20203 13.6686L2.90532 14.4049L1.33287 11.8235L3.14852 10.3112C3.0243 9.65824 3.02272 8.98959 3.14385 8.33608L1.33287 6.82693L2.90532 4.24623L5.1887 4.9787C5.72626 4.55167 6.33848 4.21702 6.99768 3.98986L7.47075 1.74824H10.6156L11.0887 3.98986C11.7431 4.22028 12.3506 4.55609 12.8844 4.98249L15.1811 4.24623L16.7535 6.82756L14.8892 8.37586L15.7674 9.32555L17.633 7.77599C17.8645 7.58338 18.0184 7.32071 18.0686 7.03267C18.1188 6.74463 18.0621 6.44902 17.9082 6.19612V6.19612Z"
        fill="white"
      />
    </svg>
  );
}
