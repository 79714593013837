export default function LogoutIcon() {
  return (
    <svg
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd">
        <g
          id="dashboard"
          transform="translate(-1113.000000, -122.000000)"
          fill="#ffffff"
          fillRule="nonzero">
          <path
            d="M1118.1394,122 L1118.1394,128.9397 L1119.8606,128.9397 L1119.8606,122 L1118.1394,122 Z M1114.75708,123.75708 C1113.67129,124.84287 1113,126.34314 1113,128 C1113,131.31371 1115.68629,134 1119,134 C1122.31371,134 1125,131.31371 1125,128 C1125,126.34314 1124.3287,124.84287 1123.24292,123.75708 L1122.03442,124.96558 C1122.81102,125.74216 1123.29126,126.8151 1123.29126,128 C1123.29126,130.36981 1121.3698,132.29126 1119,132.29126 C1116.63019,132.29126 1114.70874,130.36981 1114.70874,128 C1114.70874,126.8151 1115.18899,125.74216 1115.96558,124.96558 L1114.75708,123.75708 Z"
            id="Shape"></path>
        </g>
      </g>
    </svg>
  );
}
