import { useCallback, useEffect, useRef, useState } from "react";
import { UseMutationResult } from "@tanstack/react-query";
import {
  DBTranscription,
  TranscriptionCreateInput,
} from "../../../../api/transcriptions";
import URLIcon from "../../../../icons/URLIcon";
import {
  validateUrl,
  youtubeRegex,
  webRegex,
  audioFileRegex,
} from "../../../../lib/utils";
import { useError } from "../../../providers/ErrorProvider";
import { useLanguage } from "../../../providers/LanguageProvider";
import SecondaryButton from "../../../shared/buttons/SecondaryButton";

// - Types

// eslint-disable-next-line @typescript-eslint/ban-types
export type UrlProps = {};

export interface UrlViewProps {
  onClickFetchUrl: (textUrl: string) => void;
  inputs: {
    textUrl: string;
    onChangeTextUrl: (value: string) => void;
  };
  inputRef: React.RefObject<HTMLInputElement>;
}

// - View

export const UrlView = ({
  onClickFetchUrl,
  inputs: { textUrl, onChangeTextUrl },
  inputRef,
}: UrlViewProps) => (
  <>
    <div className="my-4 w-full text-center text-xl font-thin">
      Transcribe media or YouTube URL
    </div>

    <div className="w-full">
      <input
        type="text"
        className="w-full rounded-md border bg-transparent p-4 outline-none hover:bg-gray-900 focus:bg-gray-900"
        value={textUrl}
        onChange={(e) => onChangeTextUrl(e.target.value)}
        ref={inputRef}
      />
    </div>

    <div className="mt-4 w-full">
      <SecondaryButton
        test-id="confirm-url-button"
        onClick={() => onClickFetchUrl(textUrl)}
        className="sm:1/4 mx-auto w-80 border-greyish2 bg-darkBlue-2 hover:bg-black"
      >
        <span className="mr-4">
          <URLIcon />
        </span>
        <span className="text-sm font-medium">Fetch URL</span>
      </SecondaryButton>
    </div>
  </>
);

// - Default export

const Url = ({
  createTranscriptionMutation,
}: {
  createTranscriptionMutation: UseMutationResult<
    DBTranscription,
    unknown,
    TranscriptionCreateInput,
    unknown
  >;
}) => {
  const [, setError] = useError();

  const [textUrl, setTextUrl] = useState("");

  const { language } = useLanguage();

  const inputRef = useRef<HTMLInputElement>(null);

  const onClickFetchUrl = useCallback(
    async (textUrl: string) => {
      let title = textUrl;
      let url = textUrl;

      if (validateUrl(textUrl, youtubeRegex)) {
        let youtubeLink = textUrl;
        if (textUrl.slice(0, 4) !== "http") {
          youtubeLink = "https://" + textUrl;
        }

        url = youtubeLink;
        title = "YouTube Video";
      } else if (
        validateUrl(textUrl, webRegex) &&
        validateUrl(textUrl, audioFileRegex)
      ) {
        title = "Web Audio";
      } else {
        setError(
          "URL you entered is incorrect. Please check you are using a proper url. (make sure you URL doesnt have extra spaces or characters)"
        );

        return;
      }

      await createTranscriptionMutation.mutateAsync({
        title,
        language,
        word_timestamps: true,
        diarize: true,
        url,
      });
    },
    [createTranscriptionMutation, language, setError]
  );

  useEffect(() => {
    inputRef.current?.focus();

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onClickFetchUrl((e.target as any).value);
      }
    };

    inputRef.current?.addEventListener("keydown", onKeyDown);
    const ref = inputRef.current;

    return () => {
      ref?.removeEventListener("keydown", onKeyDown);
    };
  }, [onClickFetchUrl]);

  return (
    <UrlView
      onClickFetchUrl={onClickFetchUrl}
      inputs={{
        textUrl,
        onChangeTextUrl: setTextUrl,
      }}
      inputRef={inputRef}
    />
  );
};

export default Url;
