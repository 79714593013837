const HamburgerIcon = () => (
  <svg
    width="25"
    height="17"
    viewBox="0 0 25 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 16.6667H25V13.8889H0V16.6667ZM0 9.72222H25V6.94444H0V9.72222ZM0 0V2.77778H25V0H0Z"
      fill="#F8F8F8"
    />
  </svg>
);

export default HamburgerIcon;
