import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from "react";
import { middlewareHost } from "../../config/api";

export enum RecordingState {
  Idle = "idle",
  Recording = "recording",
  Loading = "loading",
}

export enum RecorderTranscriptionEventName {
  TranscriptionStarted = "transcription_started",
  TranscriptionFinished = "transcription_finished",
  NewSegment = "new_segment",
}

export type RecorderTranscriptionSegment = {
  start: number;
  end: number;
  text: string;
};

export type RecorderTranscriptionEvent =
  | {
      event: RecorderTranscriptionEventName.TranscriptionStarted;
      data: {
        transcription_id: string;
        created_at: string;
      };
    }
  | {
      event: RecorderTranscriptionEventName.TranscriptionFinished;
      data: {
        transcription_id: string;
      };
    }
  | {
      event: RecorderTranscriptionEventName.NewSegment;
      data: {
        prediction_segment: RecorderTranscriptionSegment;
        segments: RecorderTranscriptionSegment[];
      };
    };

export const useStreamingRecorder = ({
  onStop,
  onError,
}: {
  onStop?: (duration: number) => void;
  onError?: (error: any) => void;
} = {}) => {
  const [recordingState, setRecordingState] = useState<RecordingState>(
    RecordingState.Idle
  );

  const [transciptionSegments, setTranscriptionSegments] = useState<
    RecorderTranscriptionSegment[]
  >([]);

  const [transcriptionId, setTranscriptionId] = useState<string | null>(null);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    // set state to idle when an error occurs
    if (recordingState !== RecordingState.Idle && onError) {
      setRecordingState(RecordingState.Idle);
    }
  }, [onError]);

  const initializeRecorder = async () => {
    setRecordingState(RecordingState.Loading);
    if (navigator.mediaDevices) {
      let startTime: number = 0;
      let socket: WebSocket = new WebSocket(
        middlewareHost.replace("http", "ws") + "/transcribe_ws"
      );
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const mediaRecorder = new MediaRecorder(stream);

      let segmentCache: RecorderTranscriptionSegment[] = [];

      const stopRecording = () => {
        if (mediaRecorder.state === "recording") {
          mediaRecorder.stop();
        }
        stream.getTracks().forEach((track) => track.stop());
        if (socket) {
          socket.close();
        }
        setRecordingState(RecordingState.Idle);
      };

      // declare mediaRecorder parameters
      mediaRecorder.onstart = (e: Event) => {
        startTime = e.timeStamp;
      };

      mediaRecorder.ondataavailable = (e: BlobEvent) => {
        let blob = new Blob([e.data], { type: "audio/webm" });
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        if (socket) {
          reader.onloadend = () => {
            let base64data = reader.result;
            socket?.send(
              JSON.stringify({
                event: "new_audio",
                audio: base64data,
                recording_start: startTime,
                recording_time: e.timeStamp,
              })
            );
          };
        }
      };

      mediaRecorder.onstop = (e: Event) => {
        stopRecording();
      };

      socket.onerror = (error) => {
        console.warn(`WebSocket error: ${error}`);
        onError && onError("WebSocket error");
        stopRecording();
      };

      socket.onmessage = (event) => {
        const parsedData = JSON.parse(event.data) as RecorderTranscriptionEvent;
        // console.log("Received message from server", parsedData);

        if (parsedData.event === RecorderTranscriptionEventName.NewSegment) {
          segmentCache = [...segmentCache, ...parsedData.data.segments];
          if (parsedData.data.prediction_segment) {
            setTranscriptionSegments([
              ...segmentCache,
              parsedData.data.prediction_segment,
            ]);
          } else {
            setTranscriptionSegments(segmentCache);
          }
        } else if (
          parsedData.event ===
          RecorderTranscriptionEventName.TranscriptionFinished
        ) {
          stopRecording();
          onStop && onStop(event.timeStamp - startTime);
        } else if (
          parsedData.event ===
          RecorderTranscriptionEventName.TranscriptionStarted
        ) {
          setTranscriptionId(parsedData.data.transcription_id);
        }
      };

      socket.onopen = () => {
        console.log("connected to websocket");
        getAccessTokenSilently().then((token) => {
          socket.send(
            JSON.stringify({
              token: token,
            })
          );
          mediaRecorder.start(100);
          setRecordingState(RecordingState.Recording);
        });
      };

      return mediaRecorder;
    } else {
      console.warn("No support for media devices.");
      onError && onError("No support for media devices.");
    }
  };

  return {
    initializeRecorder,
    recordingState,
    transciptionSegments,
    transcriptionId,
  };
};

const useRecorder = ({
  onStop,
}: {
  onStop?: (blob: any, duration: number) => void;
} = {}) => {
  const [isRecording, seIsRecording] = useState(false);
  const [startRecordingTimer, setStartRecordingTimer] = useState(false);
  const [recordingTime, setRecordingTimer] = useState(0);
  const [recordingBlob, setRecordingBlob] = useState<any>(null);

  useEffect(() => {
    let timer: any = null;

    if (startRecordingTimer && isRecording) {
      timer = setTimeout(() => {
        setRecordingTimer(recordingTime + 1);
      }, 1000);
    }

    if (!startRecordingTimer && recordingTime > 0) {
      setRecordingTimer(0);
    }

    return () => clearTimeout(timer);
  }, [startRecordingTimer, recordingTime, isRecording]);

  const initializeRecorder = async () => {
    if (navigator.mediaDevices) {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      const audioChunks: any[] = [];

      const mediaRecorder = new MediaRecorder(stream);

      mediaRecorder.addEventListener("start", () => {
        setStartRecordingTimer(true);
        setRecordingTimer(0);
        seIsRecording(true);
      });

      mediaRecorder.addEventListener("dataavailable", (event) => {
        audioChunks.push(event.data);
      });

      mediaRecorder.addEventListener("pause", () => {
        seIsRecording(false);
      });

      mediaRecorder.addEventListener("resume", () => {
        seIsRecording(true);
      });

      mediaRecorder.addEventListener("stop", () => {
        if (startRecordingTimer) {
          setStartRecordingTimer(false);
        }

        setStartRecordingTimer(false);

        seIsRecording(false);
        const audioBlob = new Blob(audioChunks, { type: "audio/mp3;" });

        setRecordingBlob(audioBlob);

        onStop && onStop(audioBlob, recordingTime);

        if (mediaRecorder.state !== "inactive") {
          mediaRecorder.stop();
        }

        stream.getTracks().forEach((track) => track.stop());
      });

      return mediaRecorder;
    } else {
      console.warn("No support for media devices.");
    }
  };

  return {
    initializeRecorder,
    isRecording,
    recordingTime,
    recordingBlob,
  };
};

export default useRecorder;
