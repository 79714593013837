import React from "react";

const RecordingStopIcon = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="17.5" cy="17.5" r="17" stroke="#5977E5" />
    <path
      d="M23.1136 11H11.8864C11.6514 11.0003 11.4261 11.0937 11.2599 11.2599C11.0937 11.4261 11.0003 11.6514 11 11.8864V23.1136C11.0003 23.3486 11.0937 23.5739 11.2599 23.7401C11.4261 23.9063 11.6514 23.9997 11.8864 24H23.1136C23.3486 23.9997 23.5739 23.9063 23.7401 23.7401C23.9063 23.5739 23.9997 23.3486 24 23.1136V11.8864C23.9997 11.6514 23.9063 11.4261 23.7401 11.2599C23.5739 11.0937 23.3486 11.0003 23.1136 11V11ZM22.8182 22.8182H12.1818V12.1818H22.8182V22.8182Z"
      fill="#CA1919"
    />
  </svg>
);

export default RecordingStopIcon;
