export default function CloseIcon({ color = "#e5e5e5", size = "14" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <path
          d="M660,24.5857864 L653.707107,18.2928932 C653.316582,17.9023689 652.683418,17.9023689 652.292893,18.2928932 C651.902369,18.6834175 651.902369,19.3165825 652.292893,19.7071068 L658.585786,26 L652.292893,32.2928932 C651.902369,32.6834175 651.902369,33.3165825 652.292893,33.7071068 C652.683418,34.0976311 653.316582,34.0976311 653.707107,33.7071068 L660,27.4142136 L666.292893,33.7071068 C666.683418,34.0976311 667.316582,34.0976311 667.707107,33.7071068 C668.097631,33.3165825 668.097631,32.6834175 667.707107,32.2928932 L661.414214,26 L667.707107,19.7071068 C668.097631,19.3165825 668.097631,18.6834175 667.707107,18.2928932 C667.316582,17.9023689 666.683418,17.9023689 666.292893,18.2928932 L660,24.5857864 Z"
          id="path-jwekh0p__z-1"
        ></path>
      </defs>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="login" transform="translate(-652.000000, -18.000000)">
          <mask id="mask-jwekh0p__z-2" fill="white">
            <use href="#path-jwekh0p__z-1"></use>
          </mask>
          <use
            id="Icon"
            fill={color}
            fillRule="nonzero"
            href="#path-jwekh0p__z-1"
          ></use>
        </g>
      </g>
    </svg>
  );
}
