import cx from "classnames";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "./audioPlayerUI.scss";

const AudioPlayerUI = ({
  url,
  listenInterval,
  afterPlayerLoadCallback,
  onListen,
  audioPlayerRef,
  recordButton,
  audioDownload,
}: {
  url: string;
  listenInterval?: number;
  afterPlayerLoadCallback?: () => void;
  onListen?: (e: { playedSeconds: number }) => void;
  audioPlayerRef?: React.RefObject<AudioPlayer>;
  recordButton?: boolean;
  audioDownload?: () => void;
}) => {
  const defaultAudioRef = useRef<AudioPlayer>(null);
  const audioRef = audioPlayerRef || defaultAudioRef;
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [showPlaybackSpeedPanel, setShowPlaybackSpeedPanel] = useState(false);
  const [resetPlayer, setResetPlayer] = useState(false);

  const updatePlaybackSpeed = useCallback(
    (playbackSpeed: number) => {
      if (playbackSpeed && audioRef.current?.audio.current) {
        audioRef.current.audio.current.playbackRate = playbackSpeed;
        setPlaybackSpeed(playbackSpeed);
      }
    },
    [audioRef]
  );

  const fixMediaDuration = useCallback(() => {
    const audio = audioRef.current?.audio.current;
    if (audio) {
      if (audio.duration === Infinity) {
        // set it to bigger than the actual duration
        audio.currentTime = 1e101;
        setResetPlayer(true);
      }
    }
  }, [audioRef]);

  useEffect(() => {
    if (audioRef.current?.audio.current) {
      audioRef.current.audio.current.currentTime = 0;
      setResetPlayer(false);
    }
  }, [audioRef, resetPlayer]);

  // - Additional controls

  const customAdditionalControls = useMemo(
    () => [
      <button
        key="playback-speed-button"
        onClick={() => setShowPlaybackSpeedPanel(!showPlaybackSpeedPanel)}
        className="playback-speed-button"
      >
        {playbackSpeed}X
      </button>,
      <div
        key="playback-speed-panel"
        className={cx(
          "playback-speed-panel",
          showPlaybackSpeedPanel ? "show" : "hide"
        )}
      >
        <ul>
          <li
            onClick={() => {
              updatePlaybackSpeed(2);
              setShowPlaybackSpeedPanel(false);
            }}
            className={playbackSpeed === 2 ? "active" : ""}
          >
            2X
          </li>
          <li
            onClick={() => {
              updatePlaybackSpeed(1.5);
              setShowPlaybackSpeedPanel(false);
            }}
            className={playbackSpeed === 1.5 ? "active" : ""}
          >
            1.5X
          </li>
          <li
            onClick={() => {
              updatePlaybackSpeed(1);
              setShowPlaybackSpeedPanel(false);
            }}
            className={playbackSpeed === 1 ? "active" : ""}
          >
            1X
          </li>
        </ul>
      </div>,
      <button
        key="download-button"
        onClick={audioDownload}
        className="player-download-button"
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.25 18C1.63125 18 1.10175 17.7799 0.6615 17.3396C0.2205 16.8986 0 16.3687 0 15.75V12.375H2.25V15.75H15.75V12.375H18V15.75C18 16.3687 17.7799 16.8986 17.3396 17.3396C16.8986 17.7799 16.3687 18 15.75 18H2.25ZM9 13.5L3.375 7.875L4.95 6.24375L7.875 9.16875V0H10.125V9.16875L13.05 6.24375L14.625 7.875L9 13.5Z"
            fill="white"
          />
        </svg>
      </button>,

      // ,
      // recordButton && (
      //   <button
      //     onClick={() => playerRecordingButtonClick()}
      //     className="player-record-button"
      //   >
      //     <RecordingStartIcon />
      //   </button>
      // ),
    ],
    [audioDownload, playbackSpeed, showPlaybackSpeedPanel, updatePlaybackSpeed]
  );

  // - Render

  return (
    <div
      className={cx("audio-player-wrapper", {
        "player-with-record-button": recordButton,
      })}
    >
      <AudioPlayer
        src={url}
        volume={0.5}
        autoPlay={false}
        autoPlayAfterSrcChange={false}
        onLoadedMetaData={afterPlayerLoadCallback}
        showSkipControls={false}
        showJumpControls={false}
        loop={false}
        ref={audioRef}
        onCanPlay={fixMediaDuration}
        listenInterval={listenInterval}
        // We only need to request a subset of the props, so can explicit any safely
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onListen={onListen as any}
        customAdditionalControls={customAdditionalControls}
        customIcons={{
          play: (
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.666671 16C0.489859 16 0.320289 15.9298 0.195264 15.8047C0.0702385 15.6797 0 15.5101 0 15.3333V0.666557C1.98341e-05 0.55071 0.0302273 0.436866 0.0876452 0.336248C0.145063 0.235631 0.227709 0.151712 0.327438 0.0927628C0.427166 0.033814 0.540535 0.00186991 0.656369 7.96289e-05C0.772203 -0.00171065 0.886504 0.0267146 0.988007 0.0825533L14.3214 7.41594C14.426 7.47349 14.5131 7.55804 14.5738 7.66076C14.6345 7.76349 14.6666 7.88062 14.6666 7.99994C14.6666 8.11927 14.6345 8.2364 14.5738 8.33912C14.5131 8.44185 14.426 8.5264 14.3214 8.58395L0.988007 15.9173C0.889574 15.9715 0.779038 16 0.666671 16ZM1.33334 1.79323V14.2067L12.6168 7.99994L1.33334 1.79323Z"
                fill="#F8F8F8"
              />
            </svg>
          ),
          pause: (
            <svg
              width="12"
              height="16"
              viewBox="0 0 12 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 16H4V0H0V16ZM8 0V16H12V0H8Z" fill="#F8F8F8" />
            </svg>
          ),
        }}
      />
    </div>
  );
};

export default AudioPlayerUI;
