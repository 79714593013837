import React from "react";

const Contact = () => {
  return (
    <div className="main-section">
      <div className="row">
        <main className="main-content">
          <div className="container">
            <p className="large-text">Contact</p>
            <p className="main-text">
              The contact us over email:{" "}
              <span className="italic">hello[at]conjecture.dev</span>
            </p>

            <p className="main-text">
              For more info about Conjecture, you can visit our{" "}
              <a className="link-white" href="https://conjecture.dev">
                website.
              </a>
            </p>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Contact;
