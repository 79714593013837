import cx from "classnames";
import { forwardRef, InputHTMLAttributes } from "react";

// - Types

export type InputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "onChange"
> & {
  onChange?: (value: string) => void;
};

// - Default export

// eslint-disable-next-line react/display-name
const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, disabled, onChange, ...props }: InputProps, ref) => (
    <input
      {...props}
      ref={ref}
      onChange={(e) => onChange?.(e.target.value)}
      disabled={disabled}
      className={cx(
        "w-full rounded-md border border-gray-500 bg-transparent px-4 py-3 transition-all hover:bg-black focus:bg-black focus:outline-none",
        disabled && "opacity-80 hover:bg-transparent",
        className
      )}
    />
  )
);

export default Input;
