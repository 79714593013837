import { useMemo } from "react";
import { UseQueryResult } from "@tanstack/react-query";

// - Success

export const useSuccessfullyLoadedChain = (...queries: UseQueryResult[]) =>
  useMemo(() => queries.every((query) => query.isSuccess), [queries]);

// - Default export

export const useLoadingChain = (...queries: UseQueryResult[]) =>
  useMemo(
    () => queries.some((query) => query.isLoading || query.isFetching),
    [queries]
  );

export default useLoadingChain;
