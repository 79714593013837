import cx from "classnames";
import { ButtonHTMLAttributes, PropsWithChildren } from "react";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const PrimaryButton = ({
  onClick,
  classes,
  children,
}: PropsWithChildren<{
  onClick: () => void;
  classes: string;
}>) => (
  <button
    className={cx(
      "mb-4 flex h-36 w-11/12 items-center rounded-md border bg-darkBlue-1 p-6 leading-10 transition-colors hover:bg-black sm:mr-4 md:w-96",
      classes
    )}
    test-id="transcribe-url-button"
    onClick={onClick}
  >
    {children}
  </button>
);

export default PrimaryButton;
