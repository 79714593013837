export default function DownArrow({ color = "#ffffff" }) {
  return (
    <svg
      width="15"
      height="6"
      viewBox="0 0 15 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_443_351)">
        <path
          d="M12.5657 0.223145L7.47474 5.5565"
          stroke={color}
          strokeWidth="0.545455"
        />
        <path
          d="M2.43433 0.223145L7.52526 5.5565"
          stroke={color}
          strokeWidth="0.545455"
        />
      </g>
      <defs>
        <clipPath id="clip0_443_351">
          <rect
            width="6"
            height="15"
            fill="white"
            transform="translate(15) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
