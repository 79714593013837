// Here we list all the query keys that we'll use. We map a query name to a path,
// which is used to fetch the data.

import {
  FreeUsageGetResult,
  SubscriptionGetResult,
  CheckoutSessionCreateResult,
  PortalSessionCreateResult,
} from "./subscriptions";
import {
  DBPublicTranscription,
  DBTranscription,
  TranscriptionsGetResults,
  TranscriptionUpdateResult,
} from "./transcriptions";

export enum ApiQueryKeys {
  SubscriptionGet = "/get-subscription",
  CheckoutSessionCreate = "/checkout-session",
  PortalSessionCreate = "/portal-session",
  FreeUsageGet = "/get-free-usage",
  TranscriptionsGet = "/list-transcriptions/:offset/:limit",
  TranscriptionsCreate = "/transcriptions",
  PublicTranscriptionGet = "/public/transcriptions/:uuid",
  TranscriptionGet = "/transcriptions/:uuid",
  TranscriptionUpdate = "/transcriptions/:uuid",
  TranscriptionDelete = "/transcriptions/:uuid",
}

// Each of the query results are typed.

export type QueryResults = {
  [ApiQueryKeys.SubscriptionGet]: SubscriptionGetResult;
  [ApiQueryKeys.FreeUsageGet]: FreeUsageGetResult;
  [ApiQueryKeys.TranscriptionsGet]: TranscriptionsGetResults;
  [ApiQueryKeys.TranscriptionGet]: DBTranscription;
  [ApiQueryKeys.PublicTranscriptionGet]: DBPublicTranscription;
};

export type MutationResults = {
  [ApiQueryKeys.TranscriptionsCreate]: DBTranscription;
  [ApiQueryKeys.CheckoutSessionCreate]: CheckoutSessionCreateResult;
  [ApiQueryKeys.PortalSessionCreate]: PortalSessionCreateResult;
  [ApiQueryKeys.TranscriptionUpdate]: TranscriptionUpdateResult;
};
