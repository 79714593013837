import classnames from "classnames";

import Ring from "./ring/Ring";
import Ripple from "./ripple/Ripple";
import Dots from "./dots/Dots";

import style from "./Loader.module.scss";
import { useMemo } from "react";

const Loader = ({
  color = "white",
  type = "ripple",
  text = "Loading ...",
}: {
  color?: "white";
  type?: string;
  text?: string;
}) => {
  const Spinner = useMemo(() => {
    if (type === "ripple") return <Ripple color={color} />;
    if (type === "ring") return <Ring color={color} />;
    if (type === "dots") return <Dots color={color} />;
    return null;
  }, [color, type]);

  return (
    <div className={style.wrapper}>
      {Spinner}
      {text && (
        <div className={classnames(style.loaderText, style[color])}>{text}</div>
      )}
    </div>
  );
};

export default Loader;
