const ShoppingCart = ({ color = "#6384FF" }: { color?: string }) => (
  <svg
    width="24"
    height="21"
    viewBox="0 0 24 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.969697 4.42799e-05C0.712517 4.42799e-05 0.465871 0.110669 0.284018 0.307582C0.102164 0.504494 0 0.771565 0 1.05004C0 1.32852 0.102164 1.59559 0.284018 1.7925C0.465871 1.98942 0.712517 2.10004 0.969697 2.10004H3.12242L5.66691 13.125C5.88315 14.0595 6.656 14.7 7.54521 14.7H19.6373C20.513 14.7 21.2567 14.07 21.4865 13.1565L24 3.15004H21.9695L19.6364 12.6H7.54424L5.00073 1.57504C4.89566 1.12273 4.65345 0.721685 4.3129 0.436123C3.97235 0.150561 3.55314 -0.00299557 3.12242 4.42799e-05H0.969697ZM18.4242 14.7C16.8291 14.7 15.5152 16.1228 15.5152 17.85C15.5152 19.5773 16.8291 21 18.4242 21C20.0194 21 21.3333 19.5773 21.3333 17.85C21.3333 16.1228 20.0194 14.7 18.4242 14.7ZM9.69697 14.7C8.10182 14.7 6.78788 16.1228 6.78788 17.85C6.78788 19.5773 8.10182 21 9.69697 21C11.2921 21 12.6061 19.5773 12.6061 17.85C12.6061 16.1228 11.2921 14.7 9.69697 14.7ZM12.6061 4.42799e-05V5.25003H9.69697L13.5758 9.45002L17.4545 5.25003H14.5455V4.42799e-05H12.6061ZM9.69697 16.8C10.2439 16.8 10.6667 17.2578 10.6667 17.85C10.6667 18.4422 10.2439 18.9 9.69697 18.9C9.15006 18.9 8.72727 18.4422 8.72727 17.85C8.72727 17.2578 9.15006 16.8 9.69697 16.8ZM18.4242 16.8C18.9712 16.8 19.3939 17.2578 19.3939 17.85C19.3939 18.4422 18.9712 18.9 18.4242 18.9C17.8773 18.9 17.4545 18.4422 17.4545 17.85C17.4545 17.2578 17.8773 16.8 18.4242 16.8Z"
      fill={color}
    />
  </svg>
);

export default ShoppingCart;
