import React from "react";
import DownArrow from "../../../icons/DownArrow";

interface ExtendablePanelProps {
  content: React.ReactNode;
  title: string;
  onPanelClick: () => void;
  isOpen: boolean;
}

const ExtendablePanel = ({
  content,
  title,
  isOpen,
  onPanelClick,
}: ExtendablePanelProps) => (
  <div
    className={`relative mt-4 rounded-md border border-darkBlue-5 bg-darkBlue-2 px-0 align-middle text-sm font-light ${
      isOpen ? "h-[76%] overflow-hidden" : ""
    }${!content ? "opacity-30" : ""}`}
  >
    <h4
      className={`${
        content ? "cursor-pointer hover:bg-darkBlue-1" : ""
      } md:text- self-center rounded-md bg-darkBlue-2 py-4 px-4 text-xs lg:text-base`}
      onClick={() => {
        if (content) {
          onPanelClick();
        }
      }}
    >
      {title}
      <i className="absolute right-2 top-6 h-3">
        <DownArrow />
      </i>
    </h4>
    <div className={`h-[76%] overflow-y-auto`}>{isOpen && content}</div>
  </div>
);

export default ExtendablePanel;
