export const ROUTES_TRANSCRIPTIONS_BASE = "/transcriptions";

export const newTranscriptionDashboard = `${ROUTES_TRANSCRIPTIONS_BASE}/new`;

export const newTranscription = (type: string) =>
  `${newTranscriptionDashboard}/${type}`;

export const routes = {
  home: "/",
  about: "/about",
  contact: "/contact",
  legal: "/legal",
  subscriptions: "/subscriptions",
  newTranscription: newTranscriptionDashboard,
  newTranscriptionType: newTranscription(":type"),
  newTranscriptionRecording: newTranscription("record"),
  newTranscriptionUrl: newTranscription("url"),
  newTranscriptionUpload: newTranscription("fileUpload"),
  transcription: (uuid: string) => `${ROUTES_TRANSCRIPTIONS_BASE}/${uuid}`,
  transcribe: (uuid: string) =>
    `${ROUTES_TRANSCRIPTIONS_BASE}/${uuid}/transcribe`,
  transcriptionPublic: (uuid: string) =>
    `public${ROUTES_TRANSCRIPTIONS_BASE}/${uuid}`,
};
