import { Outlet } from "react-router-dom";
import SubscriptionProvider from "../components/providers/SubscriptionProvider";
import { FreeUsageRemainingView } from "../components/providers/SubscriptionProvider";

// eslint-disable-next-line @typescript-eslint/ban-types
const SecureRoutes = () => (
  <SubscriptionProvider>
    <FreeUsageRemainingView />
    <Outlet />
  </SubscriptionProvider>
);

export default SecureRoutes;
