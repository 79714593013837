// - Type

import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import CloseIcon from "../../icons/CloseIcon";

export type ModalContext = {
  openModal: (modal: Modal) => void | Promise<void>;
  closeModal: () => void;
};

export type Modal = {
  title: string;
  content: ReactNode;
  actions?: ReactNode;
};

// - Context

export const ModalContext = createContext<ModalContext>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  openModal: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeModal: () => {},
});

// - Hook

export const useModal = () => useContext(ModalContext);

// - View

const Modal = ({ title, content, actions }: Modal) => {
  const { closeModal } = useModal();

  return (
    <>
      <div className="fixed top-0 right-0 left-0 bottom-0 z-40 bg-black bg-opacity-70"></div>
      <div className="fixed top-0 right-0 left-0 z-50">
        <div className="top-10 z-50 mx-auto mt-8 flex w-full flex-col rounded-md border border-blackish3 bg-blackish2 py-4 px-5 sm:max-w-2xl">
          <div className="mb-4 flex justify-between">
            <h2 className="text-xl font-semibold">{title}</h2>
            <button onClick={closeModal} className={""}>
              <CloseIcon />
            </button>
          </div>
          <div>{content}</div>
          {actions && (
            <div className="mt-8 flex w-full justify-center">{actions}</div>
          )}
        </div>
      </div>
    </>
  );
};

// - Default export

// eslint-disable-next-line @typescript-eslint/ban-types
const ModalProvider = ({ children }: PropsWithChildren<{}>) => {
  const [modal, setModal] = useState<Modal | null>(null);

  const openModal = useCallback((modal: Modal) => {
    setModal(modal);
  }, []);

  const closeModal = useCallback(() => {
    setModal(null);
  }, []);

  const value = useMemo(
    () => ({ openModal, closeModal }),
    [openModal, closeModal]
  );

  return (
    <ModalContext.Provider value={value}>
      {children}
      {modal ? <Modal {...modal} /> : null}
    </ModalContext.Provider>
  );
};

export default ModalProvider;
