import cx from "classnames";
import { ButtonHTMLAttributes, PropsWithChildren } from "react";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: "default" | "small";
  wrapperClassName?: string;
};

const Button = ({
  children,
  className,
  wrapperClassName,
  variant = "default",
  ...props
}: PropsWithChildren<ButtonProps>) => (
  <span className={cx("flex items-center justify-center", wrapperClassName)}>
    <button
      {...props}
      className={cx(
        "flex items-center rounded-md border border-greyish2 px-4 py-2 hover:bg-gray-700 active:bg-gray-800",
        variant === "small" && "px-2 py-1 text-sm",
        className
      )}
    >
      {children}
    </button>
  </span>
);

export default Button;
