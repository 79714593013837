import { useCallback, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CloseIcon from "../../../icons/CloseIcon";
import LogoutIcon from "../../../icons/LogoutIcon";
import { Transition } from "@headlessui/react";
import HamburgerIcon from "../../../icons/HamburgerIcon";
import Logo from "./Logo";
import Button from "../Button";
import SearchBar from "../searchBar/SearchBar";

const Navbar = ({
  toggleSidebar,
  showSidebar,
}: {
  toggleSidebar: () => void;
  showSidebar: boolean;
}) => {
  const { isAuthenticated, logout, user } = useAuth0();
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [displaySearch, setDisplaySearch] = useState(false);

  const logoutWithRedirect = useCallback(
    () =>
      logout({
        returnTo: window.location.origin,
      }),
    [logout]
  );

  return (
    <header className="bg-darkBlue-2 py-4 px-6 sm:px-0">
      <div className="relative flex justify-between sm:container sm:mx-auto">
        {!displaySearch && (
          <div className="flex">
            {isAuthenticated && (
              <div className="mr-4 flex cursor-pointer items-center">
                <i onClick={toggleSidebar} style={{ width: "22px" }}>
                  {showSidebar ? <CloseIcon size="20" /> : <HamburgerIcon />}
                </i>
              </div>
            )}
            <Logo />
          </div>
        )}

        {isAuthenticated && (
          <SearchBar
            displaySearch={displaySearch}
            setDisplaySearch={setDisplaySearch}
          />
        )}

        {isAuthenticated && !displaySearch ? (
          <>
            <div className="flex min-w-[42px] cursor-pointer items-center justify-center">
              {user?.picture && (
                <img
                  src={user.picture}
                  className="h-10 w-10 rounded-full border-2 border-transparent hover:border-mainBlue"
                  onClick={() => setShowUserDropdown(!showUserDropdown)}
                />
              )}
            </div>

            <div className="absolute right-3 top-10 z-20 w-48 bg-darkBlue-2">
              <Transition
                show={showUserDropdown}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div className="z-10 flex flex-col rounded-md border border-greyish2 px-4 py-2 text-center">
                  <div
                    className="absolute right-2 cursor-pointer"
                    onClick={() => setShowUserDropdown(false)}
                  >
                    <CloseIcon />
                  </div>

                  <div className="px-4 py-2">
                    <div className="my-2 text-xl">{user?.nickname}</div>
                    <div className="my-1">{user?.name}</div>
                    <div className="mt-4">
                      <Button
                        onClick={() => logoutWithRedirect()}
                        test-id="logout-button"
                        className="w-full justify-center text-center"
                      >
                        <span className="logout-icon mr-2">
                          <LogoutIcon />
                        </span>
                        <span>Logout</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </Transition>
            </div>
          </>
        ) : null}
      </div>
    </header>
  );
};

export default Navbar;
