import { Link } from "react-router-dom";
import VerbalizeLogo from "../../../icons/VerbalizeLogo";

const Logo = () => (
  <h1 className="flex">
    <Link to="/" test-id="logo" className="flex items-center">
      <span className="mt mr-3">
        <VerbalizeLogo />
      </span>
      <span className="text-2xl font-bold">Verbalize</span>
    </Link>
  </h1>
);

export default Logo;
