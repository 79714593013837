import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { PropsWithChildren, useMemo } from "react";
import {
  QueryClient,
  QueryClientProvider,
  useQuery as underlyingUseQuery,
  useMutation as underlyingUseMutation,
} from "@tanstack/react-query";
import { middlewareHost } from "../../config/api";
import { useError } from "./ErrorProvider";

// - Types

type HTTPMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export type MutationFn<R> = (params: {
  method: HTTPMethod;
  path: string;
  params: object;
}) => Promise<R>;

type URLParameters = Record<string, string>;

type QueryKey =
  | [string]
  | [string, URLParameters]
  | [string, URLParameters, HTTPMethod]
  | [string, URLParameters, HTTPMethod, object];

type GetQueryPath = (queryKey: QueryKey) => string;

// - Utility

const defaultGetQueryPath: GetQueryPath = (queryKey) =>
  queryKey.length === 1
    ? queryKey[0]
    : Object.keys(queryKey[1]).reduce(
        (acc, key) => acc.replaceAll(`:${key}`, queryKey[1][key]),
        queryKey[0]
      );

// @todo We should be able to type a custom useQuery hook to default to this,
// read the result type from the queryKey, etc

export class QueryError extends Error {
  status: number;
  constructor(message: string, status: number) {
    super(message);
    this.name = "QueryError";
    this.status = status;
  }
}

// - Queries

export const getQueryFn =
  (
    method: HTTPMethod = "GET",
    {
      getAccessTokenSilently,
      getQueryPath,
      getParams,
      getHeaders,
    }: {
      getAccessTokenSilently?: () => Promise<string>;
      getQueryPath: GetQueryPath;
      getParams: (params: object) => object;
      getHeaders?: (
        headers: AxiosRequestConfig["headers"]
      ) => AxiosRequestConfig["headers"];
    }
  ) =>
  async ({ queryKey }: { queryKey: QueryKey }) => {
    const queryPath = getQueryPath(queryKey);

    const parameterisedMethod =
      Array.isArray(queryKey) && queryKey.length >= 3 ? queryKey[2] : method;

    // @todo params
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _params = getParams(
      Array.isArray(queryKey) && queryKey.length === 4 ? queryKey[3] : {}
    );

    let token: string | undefined;
    if (getAccessTokenSilently) {
      token = await getAccessTokenSilently();
    }

    try {
      let headers: AxiosRequestConfig["headers"] = {
        "Content-Type": "application/json",
      };

      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }

      if (getHeaders) {
        headers = getHeaders(headers);
      }

      const res = await axios.request({
        url: `${middlewareHost}${queryPath}`,
        method: parameterisedMethod,
        headers,
      });

      return await res.data;
    } catch (e) {
      if (!(e instanceof AxiosError)) {
        throw e;
      }

      if (e.response?.status === 404 || e.response?.status === 422) {
        // @todo gross
        const is404 =
          e.response?.status === 404 ||
          (e.response?.data.detail[0]?.type === "type_error.uuid" &&
            (e.response?.data.detail[0]?.loc[1] === "document_id" ||
              e.response?.data.detail[0]?.loc[1] === "uuid"));

        if (is404) {
          throw new QueryError("Not Found", 404);
        }
      }

      throw new QueryError(
        "Network response was not ok",
        e.response?.status || 500
      );
    }
  };

export const useQueryFn = (
  method: HTTPMethod = "GET",
  {
    getQueryPath,
    getParams,
  }: {
    getQueryPath?: GetQueryPath;
    getParams?: (params: object) => object;
  } = {
    getQueryPath: defaultGetQueryPath,
    getParams: (params) => params,
  }
) => {
  const { getAccessTokenSilently } = useAuth0();

  return useMemo(
    () =>
      getQueryFn(method, {
        getAccessTokenSilently,
        getQueryPath: getQueryPath ?? defaultGetQueryPath,
        getParams: getParams || ((params) => params),
      }),
    [method, getAccessTokenSilently, getQueryPath, getParams]
  );
};

export const useJWTQuery = <Results, Error = QueryError>(
  queryKey: QueryKey,
  token: string
) => {
  const queryFn = useMemo(
    () =>
      getQueryFn("GET", {
        getHeaders: (headers) => ({
          ...headers,
          Authorization: `Bearer ${token}`,
        }),
        getQueryPath: defaultGetQueryPath,
        getParams: (params) => params,
      }),
    [token]
  );

  // @todo
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return underlyingUseQuery<Results, Error>(queryKey, queryFn as any);
};

// - Mutations

export const getMutationFn =
  <Input extends object, ReturnValue>({
    getAccessTokenSilently,
    getQueryPath,
    getParams,
    getAxiosConfig,
  }: {
    getAccessTokenSilently: () => Promise<string>;
    getQueryPath: GetQueryPath;
    getParams: (params: Input) => Input;
    getAxiosConfig?: (
      config: AxiosRequestConfig<Input>,
      params: Input
    ) => AxiosRequestConfig<Input>;
  }) =>
  (queryKey: QueryKey) =>
  async (values: Input) => {
    const queryPath = getQueryPath(queryKey);

    const parameterisedMethod =
      Array.isArray(queryKey) && queryKey.length >= 3 ? queryKey[2] : "POST";

    const params = getParams(values);

    const token = await getAccessTokenSilently();

    try {
      const config: AxiosRequestConfig<Input> = {
        url: `${middlewareHost}${queryPath}`,
        method: parameterisedMethod,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: params,
      };

      const res = await axios.request(
        getAxiosConfig ? getAxiosConfig(config, values) : config
      );

      return res.data as ReturnValue;
    } catch (e) {
      if (!(e instanceof AxiosError)) {
        throw e;
      }

      throw new QueryError(
        "Network response was not ok",
        e.response?.status || 500
      );
    }
  };

export const useMutationFn = <Input extends object, ReturnValue>(
  {
    getQueryPath,
    getParams,
    getAxiosConfig,
  }: {
    getQueryPath?: GetQueryPath;
    getParams?: (params: Input) => Input;
    getAxiosConfig?: (
      config: AxiosRequestConfig<Input>,
      params: Input
    ) => object;
  } = {
    getQueryPath: defaultGetQueryPath,
    getParams: (params) => params,
    getAxiosConfig: (_params) => ({}),
  }
) => {
  const { getAccessTokenSilently } = useAuth0();

  return useMemo(
    () =>
      getMutationFn<Input, ReturnValue>({
        getAccessTokenSilently,
        getQueryPath: getQueryPath ?? defaultGetQueryPath,
        getParams: getParams || ((params) => params),
        getAxiosConfig: getAxiosConfig || ((config, _params) => config),
      }),
    [getAccessTokenSilently, getAxiosConfig, getParams, getQueryPath]
  );
};

type ReactQueryUseMutation<A, B, C, D = unknown> = typeof underlyingUseMutation<
  A,
  B,
  C,
  D
>;

type ReactQueryUseMutationProps<A, B, C, D = unknown> = Parameters<
  ReactQueryUseMutation<A, B, C, D>
>;
type ReactQueryUseMutationReturnType<A, B, C, D = unknown> = ReturnType<
  ReactQueryUseMutation<A, B, C, D>
>;

export const useMutation = <Input extends object, ReturnValue extends object>(
  queryKey: QueryKey,
  options?: ReactQueryUseMutationProps<ReturnValue, unknown, Input>[2],
  axiosOptions: AxiosRequestConfig<Input> = {}
): ReactQueryUseMutationReturnType<ReturnValue, unknown, Input> => {
  const mutationFn = useMutationFn<Input, ReturnValue>({
    getAxiosConfig: (config, _params) => ({ ...config, ...axiosOptions }),
  });

  const memoMutationFn = useMemo(
    () => mutationFn(queryKey),
    [mutationFn, queryKey]
  );

  return underlyingUseMutation<ReturnValue, unknown, Input>(
    queryKey,
    memoMutationFn,
    options
  );
};

// - Provider

// eslint-disable-next-line @typescript-eslint/ban-types
const QueryProvider = ({ children }: PropsWithChildren<{}>) => {
  const queryFn = useQueryFn();
  const mutationFn = useMutationFn();
  const [, setError] = useError();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // We can safely cast this to any, as the type isn't flexible enough
            // to support the function we're passing in.
            //
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            queryFn: queryFn as any,
            onError: (error) => {
              setError(error as string);
            },
          },
          mutations: {
            // Ditto
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            mutationFn: mutationFn as any,
          },
        },
      }),
    [mutationFn, queryFn, setError]
  );

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default QueryProvider;
