import { useEffect, useState } from "react";
import AppRoutes from "./routes/AppRoutes";
import Navbar from "./components/shared/navbar/Navbar";
import Footer from "./components/shared/footer/Footer";
import { useFeedbackButton } from "./lib/hooks/useFeedbackButton";
import { useAuth0 } from "@auth0/auth0-react";
import { ErrorView } from "./components/providers/ErrorProvider";
import TranscriptionsSidebar from "./components/shared/sidebar/TranscriptionsSidebar";
import { ErrorBoundary } from "@sentry/react";
import ExceptionView from "./components/views/ExceptionView";
import { useLocation } from "react-router-dom";
import * as Sentry from "@sentry/browser";

const App = () => {
  const location = useLocation();
  const { isAuthenticated, user } = useAuth0();
  const [showSidebar, setShowSidebar] = useState(false);

  useFeedbackButton();

  const [secondRender, setSecondRender] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setSecondRender(true);
    }, 100);
  }, []);

  useEffect(() => {
    if (isAuthenticated && user) {
      Sentry.setUser({
        id: user.sub,
        email: user.email,
      });
    }
  }, [isAuthenticated, user]);

  return (
    <ErrorBoundary
      key={location.pathname}
      fallback={(props) => <ExceptionView {...props} />}
    >
      <Navbar
        toggleSidebar={() => setShowSidebar(!showSidebar)}
        showSidebar={showSidebar}
      />
      <ErrorView />

      <div className="flex">
        {secondRender && isAuthenticated && (
          <TranscriptionsSidebar
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
          />
        )}

        <div className="w-full px-4 sm:container sm:mx-auto sm:px-2 md:px-4 lg:px-6">
          <AppRoutes />
        </div>
      </div>

      <Footer />
    </ErrorBoundary>
  );
};

export default App;
