import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import { routes, ROUTES_TRANSCRIPTIONS_BASE } from "../../../config/routes";
import {
  DBTranscription,
  TranscriptionsGetResults,
} from "../../../api/transcriptions";
import PenAndPaperIcon from "../../../icons/PenAndPaperIcon";
import WaveFormIcon from "../../../icons/WaveFormIcon";
import Dots from "../loader/dots/Dots";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ApiQueryKeys } from "../../../api";
import { Transition } from "@headlessui/react";
import SecondaryButton from "../buttons/SecondaryButton";
import { useModal } from "../../providers/ModalProvider";
import { useMutation } from "../../providers/QueryProvider";
import Button from "../Button";

// - Delete button

const DeleteButton = ({ uuid }: { uuid: string }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { openModal, closeModal } = useModal();

  // We have to use useMatch here because we're rendering outside of the
  // Transcription route, so useParams won't work.
  const {
    params: { uuid: urlUuid },
  } = useMatch(routes.transcription(":uuid")) ?? {
    params: { uuid: undefined },
  };

  const location = useLocation();

  const deleteMutation = useMutation(
    [ApiQueryKeys.TranscriptionDelete, { uuid }, "DELETE"],
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ApiQueryKeys.TranscriptionsGet]);

        if (
          location.pathname.startsWith(ROUTES_TRANSCRIPTIONS_BASE) &&
          urlUuid === uuid
        ) {
          navigate(routes.home);
        }
      },
      onSettled: () => {
        closeModal();
      },
    }
  );

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();

      openModal({
        title: "Delete transcription",
        content: "Are you sure you want to delete this transcription?",
        actions: (
          <>
            <Button
              onClick={() => {
                closeModal();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                deleteMutation.mutateAsync({});
              }}
              className="ml-2 border border-red-400 bg-red-500 text-center hover:border-red-700 hover:bg-red-500"
            >
              Delete
            </Button>
          </>
        ),
      });
    },
    [closeModal, deleteMutation, openModal]
  );

  return (
    <button className="ml-4 mr-2 text-xl hover:text-red-500" onClick={onClick}>
      &#x00d7;
    </button>
  );
};

// - View

const TranscriptionsSidebarView = ({
  show,
  transcriptions,
  fetchTranscriptions,
  fetchingTranscriptions,
}: {
  show: boolean;
  activePage: string;
  transcriptions: DBTranscription[];
  fetchTranscriptions: (amount: number) => void;
  fetchingTranscriptions: boolean;
}) => {
  const navigate = useNavigate();

  // check if we are scrolled to the bottom of the sidebar
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleScroll = (e: any) => {
    const element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      fetchTranscriptions(10);
    }
  };

  const sortedTranscriptions = useMemo(
    () =>
      transcriptions.sort(
        (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)
      ),
    [transcriptions]
  );

  return (
    <div className="absolute left-0 z-10 mx-0 flex w-32 max-w-fit sm:relative sm:w-full">
      <Transition
        unmount={false}
        show={show}
        enter="transition ease-out duration-300"
        enterFrom="transform -translate-x-full"
        enterTo="transform translate-x-0"
        leave="transition ease-in duration-200"
        leaveFrom="transform translate-x-0"
        leaveTo="transform -translate-x-full"
      >
        <aside className="h-[90vh] bg-darkBlue-1 px-4 transition-transform">
          <div className="py-8">
            <SecondaryButton
              className="m-auto w-64 border-greyish2 bg-darkBlue-2 hover:bg-black"
              onClick={() => {
                navigate(routes.newTranscription);
              }}
            >
              <PenAndPaperIcon />
              <span className="ml-2">New Transcription</span>
            </SecondaryButton>
          </div>
          {/* <p className="my-0 mt-8 border-t border-t-greyish2"> </p> */}
          <div className="h-4/5 py-2 px-8" onScroll={handleScroll}>
            <ul className="flex h-full flex-col overflow-y-auto border-t border-blackish3">
              {sortedTranscriptions.map((transcription, index) => (
                <li
                  key={index.toString() + transcription.uuid}
                  className="group my-2 flex border-separate border-spacing-4 cursor-pointer items-center rounded-md border-blackish3 bg-darkBlue-4 py-2 px-4 hover:bg-gray-700"
                  onClick={() => {
                    navigate(routes.transcription(transcription.uuid));
                  }}
                >
                  <span className="mr-2">
                    <WaveFormIcon />
                  </span>

                  <span className="w-[160px] truncate">
                    {transcription.title || transcription.created_at}
                  </span>

                  <span>
                    <DeleteButton uuid={transcription.uuid} />
                  </span>
                </li>
              ))}
              {fetchingTranscriptions && (
                <li className="my-4 text-center">
                  <Dots color="white" />
                </li>
              )}
            </ul>
          </div>
        </aside>
      </Transition>
    </div>
  );
};

// - Default export

const limit = 1000;

interface TranscriptionsSidebarProps {
  showSidebar: boolean;
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}

const TranscriptionsSidebar = ({ showSidebar }: TranscriptionsSidebarProps) => {
  const [offset, setOffset] = useState(0);

  const transcriptionsQuery = useQuery<TranscriptionsGetResults>([
    ApiQueryKeys.TranscriptionsGet,
    { offset, limit },
  ]);

  // See note above
  const {
    params: { uuid },
  } = useMatch(routes.transcription(":uuid")) ?? {
    params: { uuid: undefined },
  };

  const location = useLocation();

  const activePage = useMemo(() => {
    if (location.pathname.startsWith(ROUTES_TRANSCRIPTIONS_BASE) && uuid) {
      return uuid;
    }

    return "start";
  }, [location.pathname, uuid]);

  // - Render

  return (
    <TranscriptionsSidebarView
      show={showSidebar}
      transcriptions={transcriptionsQuery.data?.items || []}
      activePage={activePage}
      fetchTranscriptions={(offsetDelta) => setOffset(offset + offsetDelta)}
      fetchingTranscriptions={transcriptionsQuery.isFetching}
    />
  );
};

export default TranscriptionsSidebar;
