import React from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider

const providerConfig = {
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_AUTH0_CLIENTID,
  ...(import.meta.env.audience ? { audience: import.meta.env.audience } : null),
  redirectUri: window.location.origin,
};

const Auth0ProviderWithHistory = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const history = useNavigate();

  const onRedirectCallback = (appState: any) => {
    history(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      {...providerConfig}
      redirectUri={window.location.origin}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
      audience={import.meta.env.VITE_ENV}
      scope={
        "read:current_user update:current_user_metadata read:current_user_metadata read:users_permissions openid profile email"
      }
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
