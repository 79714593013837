import {
  DiarizedTranscriptionChunk,
  TranscriptionWithMetadata,
  DiarizedTranscriptionWithMetadata,
  TranscriptionSpeaker,
} from "../api/transcriptions";

export const EMPTY_TRANSCRIPTION = {
  uuid: "",
  transcription: [],
  diarizationInfo: [],
  created_at: "",
};

export const chunkedBySequentialSpeaker = (
  segments: DiarizedTranscriptionChunk["segments"]
): DiarizedTranscriptionChunk[] => {
  if (segments.length === 0) return [];

  let currentChunk: DiarizedTranscriptionChunk = {
    speaker: segments[0]?.speaker,
    segments: [],
    start: segments[0]?.start,
    end: segments[0]?.end,
  };

  const chunks: DiarizedTranscriptionChunk[] = [];

  segments.forEach((segment) => {
    if (segment.speaker !== currentChunk.speaker) {
      chunks.push(currentChunk);

      currentChunk = {
        speaker: segment.speaker,
        segments: [],
        start: segment.start,
        end: segment.end,
      };
    }

    currentChunk.segments.push(segment);

    currentChunk.end =
      currentChunk.segments[currentChunk.segments.length - 1].end;
  });

  chunks.push(currentChunk);

  return chunks;
};

// @todo unit test
export const diarizeTranscription = (
  transcription: TranscriptionWithMetadata
): DiarizedTranscriptionWithMetadata => {
  if (
    !transcription ||
    transcription?.diarizationInfo.length === 0 ||
    transcription?.transcription.length === 0
  ) {
    return {
      ...transcription,
      transcriptionWithDiarization: [],
    };
  }

  const allSegments = transcription.transcription.flatMap(
    (chunk) => chunk.segments
  );

  const transcriptionWithDiarization: DiarizedTranscriptionChunk[] = [];
  let previousSpeaker: TranscriptionSpeaker | undefined = undefined;

  allSegments.forEach((segment, index) => {
    const speaker = transcription.diarizationInfo[index];

    if (speaker !== previousSpeaker) {
      transcriptionWithDiarization.push({
        segments: [],
        start: segment.start,
        end: -1,
        speaker: null as unknown as TranscriptionSpeaker,
      });
    }

    transcriptionWithDiarization[
      transcriptionWithDiarization.length - 1
    ].segments.push({
      ...segment,
      speaker,
    });

    transcriptionWithDiarization[transcriptionWithDiarization.length - 1].end =
      segment.end;
    transcriptionWithDiarization[
      transcriptionWithDiarization.length - 1
    ].speaker = speaker;

    previousSpeaker = speaker;
  });

  return {
    ...transcription,
    transcriptionWithDiarization,
  };
};
