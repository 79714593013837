export default function ErrorIcon() {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5999 17.6667C11.9172 17.6667 12.1834 17.5592 12.3985 17.3441C12.6128 17.1298 12.7199 16.864 12.7199 16.5467C12.7199 16.2294 12.6128 15.9632 12.3985 15.7481C12.1834 15.5338 11.9172 15.4267 11.5999 15.4267C11.2826 15.4267 11.0168 15.5338 10.8025 15.7481C10.5874 15.9632 10.4799 16.2294 10.4799 16.5467C10.4799 16.864 10.5874 17.1298 10.8025 17.3441C11.0168 17.5592 11.2826 17.6667 11.5999 17.6667ZM11.5999 13.1867C11.9172 13.1867 12.1834 13.0792 12.3985 12.8641C12.6128 12.6498 12.7199 12.384 12.7199 12.0667V7.5867C12.7199 7.26937 12.6128 7.00318 12.3985 6.78814C12.1834 6.57385 11.9172 6.4667 11.5999 6.4667C11.2826 6.4667 11.0168 6.57385 10.8025 6.78814C10.5874 7.00318 10.4799 7.26937 10.4799 7.5867V12.0667C10.4799 12.384 10.5874 12.6498 10.8025 12.8641C11.0168 13.0792 11.2826 13.1867 11.5999 13.1867ZM11.5999 23.2667C10.0506 23.2667 8.59457 22.9725 7.2319 22.3841C5.86924 21.7965 4.6839 20.9987 3.6759 19.9907C2.6679 18.9827 1.87009 17.7974 1.28246 16.4347C0.694089 15.072 0.399902 13.616 0.399902 12.0667C0.399902 10.5174 0.694089 9.06137 1.28246 7.6987C1.87009 6.33603 2.6679 5.1507 3.6759 4.1427C4.6839 3.1347 5.86924 2.33651 7.2319 1.74814C8.59457 1.16051 10.0506 0.866699 11.5999 0.866699C13.1492 0.866699 14.6052 1.16051 15.9679 1.74814C17.3306 2.33651 18.5159 3.1347 19.5239 4.1427C20.5319 5.1507 21.3297 6.33603 21.9173 7.6987C22.5057 9.06137 22.7999 10.5174 22.7999 12.0667C22.7999 13.616 22.5057 15.072 21.9173 16.4347C21.3297 17.7974 20.5319 18.9827 19.5239 19.9907C18.5159 20.9987 17.3306 21.7965 15.9679 22.3841C14.6052 22.9725 13.1492 23.2667 11.5999 23.2667ZM11.5999 21.0267C14.1012 21.0267 16.2199 20.1587 17.9559 18.4227C19.6919 16.6867 20.5599 14.568 20.5599 12.0667C20.5599 9.56537 19.6919 7.4467 17.9559 5.7107C16.2199 3.9747 14.1012 3.1067 11.5999 3.1067C9.09857 3.1067 6.9799 3.9747 5.2439 5.7107C3.5079 7.4467 2.6399 9.56537 2.6399 12.0667C2.6399 14.568 3.5079 16.6867 5.2439 18.4227C6.9799 20.1587 9.09857 21.0267 11.5999 21.0267Z"
        fill="white"
      />
    </svg>
  );
}
