import classnames from "classnames";

import style from "./Ring.module.scss";

const Ring = ({
  color = "white",
  className,
}: {
  color?: "white";
  className?: string;
}) => (
  <div className={classnames(style.ringLoader, style[color], className)}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default Ring;
