const About = () => {
  return (
    <div className="main-section">
      <div className="row">
        <main className="main-content">
          <div className="container">
            <div className="center-content">
              <p className="large-text">About Verbalize</p>
              <p className="main-text">
                Verbalize, a universal transcriber for your meetings and media.
                If you havent subscribed yet, you can do it on the{" "}
                <a className="link-white" href="https://lemm.ai">
                  Lemma
                </a>{" "}
                website. For more info about Conjecture, visit our{" "}
                <a className="link-white" href="https://conjecture.dev">
                  website.
                </a>
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default About;
