const HeadphonesIcon = () => (
  <svg
    width="52"
    height="52"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 11.6C2 9.05392 3.05357 6.61212 4.92893 4.81178C6.8043 3.01143 9.34784 2 12 2C14.6522 2 17.1957 3.01143 19.0711 4.81178C20.9464 6.61212 22 9.05392 22 11.6V18.7143C22 19.6019 21.6293 20.4427 20.9867 21.0547C20.3458 21.6651 19.4864 22 18.6 22H17.4C16.5136 22 15.6542 21.6651 15.0133 21.0547C14.3707 20.4427 14 19.6019 14 18.7143V15.2857C14 14.3981 14.3707 13.5573 15.0133 12.9453C15.6542 12.3349 16.5136 12 17.4 12H19V11.6C19 9.89 18.2935 8.224 16.9935 6.97594C15.6892 5.72387 13.8951 5 12 5C10.1049 5 8.31076 5.72387 7.00653 6.97594C5.70646 8.224 5 9.89 5 11.6V12H6.6C7.48642 12 8.34576 12.3349 8.98671 12.9453C9.62932 13.5573 10 14.3981 10 15.2857V18.7143C10 19.6019 9.62932 20.4427 8.98671 21.0547C8.34576 21.6651 7.48642 22 6.6 22H5.4C4.51358 22 3.65424 21.6651 3.01329 21.0547C2.37068 20.4427 2 19.6019 2 18.7143V11.6Z"
      fill="white"
    />
  </svg>
);

export default HeadphonesIcon;
