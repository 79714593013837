import React from "react";

const RecordingStartIcon = ({ color = "#6384FF" }: { color?: string }) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="17.5" cy="17.5" r="17" stroke={color} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3102 14.2917C11.7576 14.2917 12.1204 14.6544 12.1204 15.1019V16.7222C12.1204 19.8396 14.6743 22.3935 17.7917 22.3935C20.909 22.3935 23.463 19.8396 23.463 16.7222V15.1019C23.463 14.6544 23.8257 14.2917 24.2731 14.2917C24.7206 14.2917 25.0833 14.6544 25.0833 15.1019V16.7222C25.0833 20.4608 22.2362 23.563 18.6019 23.9689V26.4445H21.0324C21.4799 26.4445 21.8426 26.8072 21.8426 27.2547C21.8426 27.7021 21.4799 28.0648 21.0324 28.0648H14.5509C14.1035 28.0648 13.7407 27.7021 13.7407 27.2547C13.7407 26.8072 14.1035 26.4445 14.5509 26.4445H16.9815V23.9689C13.3472 23.563 10.5 20.4608 10.5 16.7222V15.1019C10.5 14.6544 10.8627 14.2917 11.3102 14.2917Z"
      fill={color}
    />
    <path
      d="M17.7916 20.7732C17.2569 20.7719 16.7279 20.6633 16.236 20.4536C15.7441 20.244 15.2994 19.9377 14.9281 19.5528C14.1682 18.7864 13.7415 17.751 13.7407 16.6716V11.051C13.7386 10.5184 13.842 9.9907 14.0449 9.49828C14.2477 9.00586 14.546 8.55847 14.9226 8.18189C15.2992 7.80531 15.7466 7.507 16.239 7.30416C16.7314 7.10132 17.2591 6.99795 17.7916 7.00003C20.0632 7.00003 21.8426 8.7794 21.8426 11.051V16.6716C21.8426 18.933 20.0252 20.7732 17.7916 20.7732Z"
      fill={color}
    />
  </svg>
);

export default RecordingStartIcon;
