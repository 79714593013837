const SignIn = ({ color = "#9672D0" }: { color?: string }) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 9.45V6.3L14 10.5L9 14.7V11.55H0V9.45H9ZM1.458 13.65H3.582C4.28004 15.455 5.55371 16.9493 7.18512 17.8772C8.81652 18.8052 10.7043 19.1091 12.5255 18.737C14.3468 18.3649 15.9883 17.3399 17.1693 15.8374C18.3503 14.3349 18.9975 12.4482 19 10.5C19.001 8.54979 18.3558 6.65997 17.1749 5.15468C15.994 3.64939 14.3511 2.62243 12.528 2.24995C10.7048 1.87747 8.81505 2.18268 7.18278 3.11323C5.55051 4.04377 4.27747 5.54167 3.582 7.35H1.458C2.732 3.09015 6.522 0 11 0C16.523 0 21 4.70085 21 10.5C21 16.2991 16.523 21 11 21C6.522 21 2.732 17.9098 1.458 13.65Z"
      fill={color}
    />
  </svg>
);

export default SignIn;
