const Progress = ({
  percent,
  withText = false,
}: {
  percent: number;
  withText?: boolean | string;
}) => (
  <div className="h-2.5 w-full rounded-full bg-gray-200 dark:bg-gray-700">
    <div
      className="h-2.5 rounded-full bg-purple text-center text-xs font-semibold leading-none text-white transition-all dark:bg-purple"
      style={{ width: `${percent}%` }}
    >
      {withText === true
        ? `${Math.round(percent)}%`
        : typeof withText === "string"
        ? withText
        : null}
    </div>
  </div>
);

export default Progress;
