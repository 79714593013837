import { Route, Routes } from "react-router-dom";
import Login from "../pages/Login";
import About from "../pages/About";
import Contact from "../pages/Contact";
import NotFound from "../components/views/NotFound";
import SecureRoutes from "./SecureRoutes";
import { routes, ROUTES_TRANSCRIPTIONS_BASE } from "../config/routes";
import NewTranscription from "../pages/authorised/NewTranscription";
import StreamTranscriptionPage from "../pages/authorised/StreamTranscriptionPage";
import Transcription from "../pages/authorised/Transcription";
import Subscriptions from "../pages/authorised/Subscriptions";
import SubscriptionProvider from "../components/providers/SubscriptionProvider";
import Legal from "../pages/Legal";
import PublicTranscription from "../pages/PublicTranscription";

const AppRoutes = () => (
  <>
    <Routes>
      <Route path={ROUTES_TRANSCRIPTIONS_BASE} element={<SecureRoutes />}>
        <Route path={routes.newTranscription} element={<NewTranscription />} />
        <Route
          path={routes.newTranscriptionType}
          element={<NewTranscription />}
        />
        <Route
          path={routes.transcription(":uuid")}
          element={<Transcription />}
        />
        <Route
          path={routes.transcribe(":uuid")}
          element={<StreamTranscriptionPage />}
        />
      </Route>

      <Route
        path={routes.transcriptionPublic(":uuid")}
        element={<PublicTranscription />}
      />

      <Route
        path={routes.subscriptions}
        element={
          <SubscriptionProvider>
            <Subscriptions />
          </SubscriptionProvider>
        }
      />

      <Route path={routes.home} element={<Login />} />
      <Route path={routes.about} element={<About />} />
      <Route path={routes.contact} element={<Contact />} />
      <Route path={routes.legal} element={<Legal />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </>
);

export default AppRoutes;
