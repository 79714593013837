// - Query Results

export type TranscriptionsGetResults = {
  count: number;
  items: DBTranscription[];
};

// - Mutations

export type TranscriptionCreateInput = {
  title: string;
  language: TranscriptionLanguage;
  word_timestamps: boolean;
  diarize: boolean;
  num_speakers?: number;
} & (
  | {
      file: string;
    }
  | {
      url: string;
    }
);

export type TranscriptionUpdateInput = {
  title?: string;
  description?: string;
  visibility?: DBTranscriptionVisibility;
};

export type TranscriptionUpdateResult = {
  message: "success";
  transcription: DBTranscription;
};

// ------------------------------------------------------------------------------------------------
// @todo reorganise

export enum TranscriptionType {
  Recording = "record",
  Url = "url",
  FileUpload = "fileUpload",
}

export enum MediaPlayerType {
  Audio = "audio",
  Video = "video",
}

export enum TranscriptionLanguage {
  English = "en",
  Spanish = "es",
  Portuguese = "pt",
  Russian = "ru",
  German = "de",
  Japanese = "ja",
  Polish = "pl",
  Dutch = "nl",
  Indonesian = "id",
  Catalan = "ca",
  French = "fr",
  Turkish = "tr",
  Swedish = "sv",
  Ukrainian = "uk",
  Malay = "ms",
  Norwegian = "no",
  Finnish = "fi",
  Italian = "it",
}

export enum TranscriptionEventName {
  TranscriptionStarted = "transcription_started",
  Finish = "finish",
  Error = "error",
  Diarization = "diarization",
  NewSegment = "new_segment",
}

export type TranscriptionSpeaker = number;

export type TranscriptionSegment = {
  start: number;
  end: number;
  text: string;
};

export type DiarizedTranscriptionSegment = TranscriptionSegment & {
  speaker?: TranscriptionSpeaker;
};

export type TranscriptionEvent =
  | {
      event: TranscriptionEventName.TranscriptionStarted;
      data: {
        transcription_id: string;
        created_at: string;
      };
    }
  | {
      event: TranscriptionEventName.Finish;
      data: {
        completion: string;
        language: TranscriptionLanguage;
      };
    }
  | {
      event: TranscriptionEventName.Error;
      message: string;
    }
  | TranscriptionEventDiarization
  | TranscriptionEventNewSegment;

export type TranscriptionEventNewSegment = {
  event: TranscriptionEventName.NewSegment;
  data: {
    segments: TranscriptionSegment[];
    progress: number;
    free_usage_left?: number;
  };
};

export type TranscriptionEventDiarization = {
  event: TranscriptionEventName.Diarization;
  data: {
    labels: number[];
  };
};

export type RawTranscriptionEvent = {
  data: string;
};

export type TranscriptionChunk = {
  segments: TranscriptionSegment[];
  start: number;
  end: number;
};

export type DiarizedTranscriptionChunk = Omit<
  TranscriptionChunk,
  "segments"
> & {
  segments: DiarizedTranscriptionSegment[];
  speaker?: TranscriptionSpeaker;
};

export type Transcription = TranscriptionChunk[];

export type DiarizedTranscription = DiarizedTranscriptionChunk[];

export type TranscriptionDiarization = TranscriptionSpeaker[]; // Per-segment speaker labels

export type TranscriptionWithMetadata = {
  uuid: string;
  transcription: Transcription;
  diarizationInfo: TranscriptionDiarization;
  created_at: string;
  dbTranscription?: DBTranscription;
};

export type PostTranscriptionData = {
  summary?: string;
  notes?: string[];
  questions?: string[];
};

export enum ExtractedInfoPanelType {
  Summary = "summary",
  Questions = "questions",
  Notes = "notes",
}

export type DiarizedTranscriptionWithMetadata = TranscriptionWithMetadata & {
  transcriptionWithDiarization: DiarizedTranscription;
};

// - DB Transcription

export enum DBTranscriptionRequestType {
  Youtube = "youtube",
  File = "file",
}

export enum DBTranscriptionStatus {
  Pending = 1,
  Incomplete = 2,
  Complete = 3,
  Error = 4,
}

export enum DBTranscriptionVisibility {
  Private = "private",
  PublicWithLink = "public_with_link",
}

export type DBTranscription = {
  content: { events: TranscriptionEvent[] };
  title: string;
  description: string;
  request_type: DBTranscriptionRequestType;
  transcription_metadata: PostTranscriptionData;
  file_url: string;
  file_format: string;
  file_size: number;
  duration: number;
  times_updated: number;
  created_at: string;
  updated_at: string;
  uuid: string;
  status: DBTranscriptionStatus;
  text_content: string;
  visibility: DBTranscriptionVisibility;
  sharing_jwt?: string;
};

export type DBPublicTranscription = DBTranscription & {
  visibility: DBTranscriptionVisibility.PublicWithLink;
  sharing_jwt: string;
  user: {
    id: string;
    email: string;
  };
};

export const isPublicTranscription = (
  transcription?: DBTranscription | DBPublicTranscription
): transcription is DBPublicTranscription =>
  transcription !== undefined &&
  transcription.visibility === DBTranscriptionVisibility.PublicWithLink &&
  transcription.sharing_jwt !== undefined &&
  "user" in transcription;
