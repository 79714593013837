import { createContext, useContext, useState } from "react";
import ErrorPopup from "../shared/popups/error/Error";

// - Type

export interface ErrorContextType {
  error?: React.ReactNode;
  setError: (error: React.ReactNode | undefined) => void;
}

// - Context

export const ErrorContext = createContext<ErrorContextType>({
  error: undefined,
  setError: () => {
    throw new Error("ErrorContext not initialized");
  },
});

// - Provider

export const ErrorProvider = ({ children }: { children: React.ReactNode }) => {
  const [error, setError] = useState<React.ReactNode>();

  return (
    <ErrorContext.Provider value={{ error, setError }}>
      {children}
    </ErrorContext.Provider>
  );
};

export default ErrorProvider;

// - Hook

export const useError = () => {
  const { error, setError } = useContext(ErrorContext);

  return [error, setError] as const;
};

// - View

export const ErrorView = () => {
  const [error, setError] = useError();

  let errorContent;

  if (error instanceof Error) {
    errorContent = <p>{error.message}</p>;
  } else {
    errorContent = error;
  }

  return error ? (
    <ErrorPopup message={errorContent} close={() => setError(undefined)} />
  ) : null;
};
