import { useCallback, useEffect, useRef, useState } from "react";
import { Transcription } from "../../../api/transcriptions";
import DocumentDownload from "../../../icons/DocumentDownload";
import {
  transcriptionToSrt,
  usePhantomDownload,
} from "./DownloadTranscript.utils";

// - Types

export interface DownloadTranscriptProps {
  title: string;
  transcription: Transcription;
  loading: boolean;
}

export interface DownloadTranscriptViewProps {
  state: {
    loading: boolean;
    showDownloadFormatDropdown: boolean;
    setShowDownloadFormatDropdown: (show: boolean) => void;
  };
  actions: {
    startTranscriptDownload: (value: "srt" | "txt") => void;
  };
  refs: {
    dropdownRef: React.RefObject<HTMLDivElement>;
  };
}

// - View

export const DownloadTranscriptView = ({
  state: { loading, showDownloadFormatDropdown, setShowDownloadFormatDropdown },
  actions: { startTranscriptDownload },
  refs: { dropdownRef },
}: DownloadTranscriptViewProps) => (
  <div
    ref={dropdownRef}
    className={`transcription-controls-button relative transition-opacity hover:opacity-30 ${
      loading ? "opacity-30" : "cursor-pointer"
    }`}
    onClick={() => {
      if (!loading) {
        setShowDownloadFormatDropdown(!showDownloadFormatDropdown);
      }
    }}
  >
    {showDownloadFormatDropdown && (
      <div className="absolute top-6 -left-[30px] rounded-md border border-darkBlue-3 bg-darkBlue-1 opacity-90 shadow-sm">
        <button
          className="rounded-t-md border-b border-darkBlue-5 px-6 py-3 text-xs hover:bg-mainBlue hover:text-white"
          onClick={() => startTranscriptDownload("srt")}
        >
          .srt
        </button>
        <button
          className="rounded-b-md px-6 py-3 text-xs hover:bg-mainBlue"
          onClick={() => startTranscriptDownload("txt")}
        >
          .txt
        </button>
      </div>
    )}
    <i title="Download Transcription" test-id="download-transcript-button">
      <DocumentDownload />
    </i>
  </div>
);

// - Default export

const DownloadTranscript = ({
  title,
  transcription,
  loading,
}: DownloadTranscriptProps) => {
  const phantomDownload = usePhantomDownload();

  const dropdownRef = useRef<HTMLDivElement>(null);

  const [showDownloadFormatDropdown, setShowDownloadFormatDropdown] =
    useState(false);

  const startTranscriptDownload = useCallback(
    (format: "srt" | "txt") => {
      let titleWithExtension: string;
      let content: string;

      if (format === "srt") {
        titleWithExtension = `${title.slice(0, -4)}.srt`;
        content = transcriptionToSrt(transcription);
      } else {
        titleWithExtension = `${title.slice(0, -4)}.txt`;

        // @todo I'm not keen on pulling the text out of the DOM like this - should replace with
        //       a transcript generated from the transcription object directly
        content =
          document?.getElementById("transcriptionText")?.innerText || "";
      }

      phantomDownload(titleWithExtension, content);
    },
    [phantomDownload, title, transcription]
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDownloadFormatDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <DownloadTranscriptView
      state={{
        loading,
        showDownloadFormatDropdown,
        setShowDownloadFormatDropdown,
      }}
      actions={{ startTranscriptDownload }}
      refs={{ dropdownRef }}
    />
  );
};

export default DownloadTranscript;
