import { createContext, useContext, useMemo } from "react";
import { TranscriptionLanguage } from "../../api/transcriptions";
import useLocalStorage from "../../lib/hooks/useLocalStorage";

// - Types

export type Language = TranscriptionLanguage;

export type LanguageProvider = {
  language: Language;
  setLanguage: (language: Language) => void;
};

// - Context

export const LanguageContext = createContext<LanguageProvider>({
  language: TranscriptionLanguage.English,
  setLanguage: () => {
    throw new Error("LanguageContext not initialized");
  },
});

// - Provider

export const LanguageProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [language, setLanguage] = useLocalStorage<Language>(
    TranscriptionLanguage.English
  );

  const value = useMemo(
    () => ({ language, setLanguage }),
    [language, setLanguage]
  );

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};

// - Hook

export const useLanguage = () => useContext(LanguageContext);
