import cx from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import CloseIcon from "../../../icons/CloseIcon";
import SearchIcon from "../../../icons/SearchIcon";
import Input from "../Input";
import SearchResults from "./SearchResults";

// - Types

export type SearchBarProps = {
  displaySearch: boolean;
  setDisplaySearch: (displaySearch: boolean) => void;
};

export type SearchBarViewProps = {
  refs: {
    inputRef: React.Ref<HTMLInputElement>;
  };
  callbacks: {
    onClickIcon: React.MouseEventHandler<HTMLAnchorElement>;
    onClickClose: React.MouseEventHandler<HTMLAnchorElement>;
    onFocus: React.FocusEventHandler<HTMLInputElement>;
  };
  state: {
    displaySearch: boolean;
    setDisplaySearch: (displaySearch: boolean) => void;
    value: string;
    setValue: (value: string) => void;
  };
};

// - View

const SearchBarView = ({
  refs: { inputRef },
  callbacks: { onClickIcon, onFocus, onClickClose },
  state: { displaySearch, value, setValue },
}: SearchBarViewProps) => (
  <div
    className={cx(
      "flex w-full items-center justify-center",
      !displaySearch && "sm:w-[400px]"
    )}
  >
    <div className={cx("sm:hidden", displaySearch && "hidden")}>
      <a href="#" onClick={onClickIcon} className="hover:opacity-70">
        <SearchIcon />
      </a>
    </div>
    <div
      className={cx(
        "w-full items-center justify-start sm:flex",
        displaySearch ? "flex" : "hidden"
      )}
    >
      <div>
        <Input
          placeholder="Search..."
          tabIndex={1}
          ref={inputRef}
          value={value}
          onChange={setValue}
          onFocus={onFocus}
          className="w-full max-w-md"
        />
      </div>
      {displaySearch && (
        <a href="#" onClick={onClickClose} className="ml-4 hover:opacity-70">
          <CloseIcon />
        </a>
      )}
    </div>
    <SearchResults
      visible={displaySearch}
      query={value}
      onClickClose={onClickClose as () => void}
    />
  </div>
);

// - Default export

const SearchBar = ({ displaySearch, setDisplaySearch }: SearchBarProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  // - State

  const [value, setValue] = useState("");

  // - Keyboard events

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setDisplaySearch(false);
      }

      if (e.key === "Escape" && document.activeElement === inputRef.current) {
        inputRef.current?.blur();
      }

      if (e.key === "/" && document.activeElement !== inputRef.current) {
        e.preventDefault();
        setDisplaySearch(true);
        inputRef.current?.focus();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setDisplaySearch]);

  useEffect(() => {
    if (!value.length) {
      setDisplaySearch(false);
      inputRef.current?.blur();
    }
  }, [value.length, setDisplaySearch]);

  useEffect(() => {
    if (displaySearch) {
      inputRef.current?.focus();
    }
  }, [displaySearch]);

  // - Callbacks

  const onClickIcon = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      setDisplaySearch(true);
      inputRef.current?.focus();
    },
    [setDisplaySearch]
  );

  const onFocus = useCallback(() => {
    setDisplaySearch(true);
  }, [setDisplaySearch]);

  const onClickClose = useCallback(() => {
    setDisplaySearch(false);
    setValue("");
  }, [setDisplaySearch]);

  // - Render

  return (
    <SearchBarView
      refs={{
        inputRef,
      }}
      callbacks={{
        onClickIcon,
        onClickClose,
        onFocus,
      }}
      state={{
        displaySearch,
        setDisplaySearch,
        value,
        setValue,
      }}
    />
  );
};

export default SearchBar;
