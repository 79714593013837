import { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { QueryResults, ApiQueryKeys } from "../api";
import {
  TranscriptionEventNewSegment,
  TranscriptionEventName,
  TranscriptionEventDiarization,
} from "../api/transcriptions";
import { useJWTQuery } from "../components/providers/QueryProvider";
import ViewTranscription from "../components/views/Transcription/ViewTranscription";
import NotFound from "../errors/NotFound";
import {
  chunkedBySequentialSpeaker,
  diarizeTranscription,
} from "../lib/diarization";

// - Default export

const PublicTranscription = () => {
  const { uuid } = useParams();

  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  if (!uuid || !token) {
    throw new NotFound();
  }

  // - Query transcription

  const dbTranscription = useJWTQuery<
    QueryResults[ApiQueryKeys.PublicTranscriptionGet]
  >(
    [
      ApiQueryKeys.PublicTranscriptionGet,
      {
        uuid,
      },
      "GET",
    ],
    token
  );

  const loading = useMemo(
    () => dbTranscription.isLoading,
    [dbTranscription.isLoading]
  );

  const transcription = useMemo(() => {
    const events = dbTranscription.data?.content.events ?? [];

    const segments = events
      .filter(
        (e): e is TranscriptionEventNewSegment =>
          e.event === TranscriptionEventName.NewSegment
      )
      .flatMap((e) => e.data.segments);

    const diarizationInfo =
      events.find(
        (event): event is TranscriptionEventDiarization =>
          event.event === TranscriptionEventName.Diarization
      )?.data.labels ?? [];

    const transcription = chunkedBySequentialSpeaker(segments);

    const transcriptionWithMetadata = {
      uuid: dbTranscription.data?.uuid || uuid || "",
      transcription,
      diarizationInfo,
      created_at: dbTranscription.data?.created_at || "",
      dbTranscription: dbTranscription.data,
    };

    return diarizeTranscription({
      ...transcriptionWithMetadata,
      diarizationInfo,
    });
  }, [dbTranscription.data, uuid]);

  if (
    dbTranscription.error?.status === 404 ||
    dbTranscription.error?.status === 422 ||
    dbTranscription.failureReason?.status === 404
  ) {
    throw new NotFound();
  }

  return (
    <ViewTranscription
      state={{
        loading,
        transcription,
      }}
    />
  );
};

export default PublicTranscription;
