// A lightweight inverse-index search library
//
// by Jamie Rumbelow <jamie@conjecture.dev>

// - Config

const DEFAULT_MIN_QUERY_LENGTH = 3;
const DEFAULT_TRUNCATE_LENGTH = 100;

// - Types

export type DocumentId = string;

export type DocumentBase = {
  id: DocumentId;
};

export type WithDocumentId<O extends object> = O & DocumentBase;

export type SearchResult<O extends DocumentBase> = O & {
  _searchMeta: {
    highlights: Record<keyof O, string>;
  };
};

export type SearchClient<O extends DocumentBase> = {
  index: Index<O>;
  search: (query: string) => SearchResult<O>[];
};

type KeysMatching<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];

export type Options<O extends DocumentBase> = {
  highlight?: boolean;
  truncate?: boolean | KeysMatching<O, string>[];
  truncateLength?: number;
  operator?: "AND" | "OR";
  minQueryLength?: number;
  caching?: boolean;
};

type IndexInternalRepresentation<O extends DocumentBase> = {
  id: DocumentId;
  attributes: (keyof O)[];
};

type Index<O extends DocumentBase> = Map<
  DocumentId,
  IndexInternalRepresentation<O>[]
>;

// - Innards

const normalizeToken = (token: string): string => token.toLowerCase();

const generateIndex = <
  O extends DocumentBase,
  F extends KeysMatching<O, string>
>(
  data: O[],
  fields: F[],
  options: Options<O>
): Index<O> => {
  const index = new Map<string, { id: DocumentId; attributes: string[] }[]>();

  for (const item of data) {
    for (const key in item) {
      const value = item[key];
      if (!fields.includes(key as F) || typeof value !== "string") {
        continue;
      }

      const tokens = value.split(" ").map(normalizeToken);

      const partialTokens: string[] = [];
      const minTokenLength = options.minQueryLength ?? DEFAULT_MIN_QUERY_LENGTH;

      for (const token of tokens) {
        for (let i = minTokenLength - 1; i < token.length; i++) {
          partialTokens.push(token.slice(0, i + 1));
        }
      }

      for (const token of partialTokens) {
        const existing = index.get(token);

        if (existing) {
          if (!existing.find((e) => e.id === item.id)) {
            existing.push({ id: item.id, attributes: [key] });
            continue;
          }

          const existingItem = existing.find(
            (e) => e.id === item.id && !e.attributes.includes(key)
          );

          if (existingItem) {
            existingItem.attributes.push(key);
          }

          continue;
        } else {
          // If the token does not exist, add it to the index
          index.set(token, [{ id: item.id, attributes: [key] }]);

          continue;
        }
      }
    }
  }

  return index as Index<O>;
};

const executeSearch = <O extends DocumentBase>(
  index: Index<O>,
  query: string,
  options: Options<O>
) => {
  const tokens = query
    .split(" ")
    .filter(
      (t) =>
        t !== " " &&
        t.length >= (options.minQueryLength ?? DEFAULT_MIN_QUERY_LENGTH)
    )
    .map(normalizeToken);

  const ids: DocumentId[] = [];
  const resultsWithInternalRepresentation: Map<
    DocumentId,
    IndexInternalRepresentation<O>
  > = new Map<DocumentId, IndexInternalRepresentation<O>>();

  // First pass: construct an initial results set

  for (const token of tokens) {
    const tokenResults = index.get(token);

    if (tokenResults) {
      ids.push(...tokenResults.map((r) => r.id));
      tokenResults.forEach((r) =>
        resultsWithInternalRepresentation.set(r.id, r)
      );
    }
  }

  // Second pass: ensure that only records that match every
  // token are allowed through

  if (options.operator === "OR") {
    return [ids, resultsWithInternalRepresentation] as const;
  }

  for (const token of tokens) {
    const tokenResults = index.get(token);

    if (!tokenResults) {
      return [
        [] as DocumentId[],
        new Map<DocumentId, IndexInternalRepresentation<O>>(),
      ] as const;
    }

    if (tokenResults) {
      const tokenIds = tokenResults.map((r) => r.id);

      for (const id of ids) {
        if (!tokenIds.includes(id)) {
          ids.splice(ids.indexOf(id), 1);
          resultsWithInternalRepresentation.delete(id);
        }
      }
    }
  }

  return [ids, resultsWithInternalRepresentation] as const;
};

const highlight = (text: string, query: string) => {
  const tokens = query.split(" ");
  const regex = new RegExp(`(${tokens.join("|")})`, "gi");

  return text.replaceAll(regex, "<mark>$1</mark>");
};

const truncate = (text: string, query: string, maxLength: number): string => {
  const tokens = query.split(" ");
  const regex = new RegExp(`(${tokens.join("|")})`, "gi");

  const matches = text.match(regex) ?? [];
  const firstMatch = matches[0];

  if (!firstMatch) {
    return text;
  }

  const startIndex = text.indexOf(firstMatch);
  const endIndex = startIndex + firstMatch.length;

  let start = Math.max(0, startIndex - maxLength);
  let end = Math.min(text.length, endIndex + maxLength);

  if (text[start] !== " ") {
    start = text.lastIndexOf(" ", start);
    start = Math.max(0, start);
  }

  if (text[end] !== " ") {
    end = text.indexOf(" ", end);
    end = Math.min(text.length, end);
  }

  let truncated = text.slice(start, end);
  if (start !== 0) {
    truncated = `... ${truncated.trim()}`;
  }

  if (end !== text.length - 1) {
    truncated = `${truncated.trim()} ...`;
  }

  return truncated;
};

const checkOptions = <O extends DocumentBase>(options: Options<O>) => {
  if (options.minQueryLength && options.minQueryLength < 1) {
    throw new Error("minQueryLength must be greater than 0");
  }

  if (
    options.operator &&
    options.operator !== "AND" &&
    options.operator !== "OR"
  ) {
    throw new Error("operator must be either AND or OR");
  }

  if (options.truncate && !options.highlight) {
    throw new Error("truncate requires highlight to be true");
  }
};

// - Exports

export const newSearchClient = <
  O extends DocumentBase,
  F extends KeysMatching<O, string>
>(
  data: O[],
  fields: F[],
  options: Options<O> = {
    highlight: true,
    truncate: true,
    truncateLength: DEFAULT_TRUNCATE_LENGTH,
    operator: "AND",
    minQueryLength: DEFAULT_MIN_QUERY_LENGTH,
    caching: true,
  }
): SearchClient<O> => {
  checkOptions(options);

  const index = generateIndex(data, fields, options);
  const cache = new Map<string, SearchResult<O>[]>();

  const search = (query: string) => {
    if (query.length < (options.minQueryLength ?? DEFAULT_MIN_QUERY_LENGTH)) {
      return [];
    }

    if (options.caching && cache.has(query)) {
      return cache.get(query) as SearchResult<O>[];
    }

    const [ids, resultsWithInternalRepresentation] = executeSearch(
      index,
      query,
      options
    );

    const results = data

      // Filtering
      .filter((item) => ids.includes(item.id))

      // Ranking
      .sort((a, b) => {
        const aInternalRepresentation = resultsWithInternalRepresentation.get(
          a.id
        );

        const bInternalRepresentation = resultsWithInternalRepresentation.get(
          b.id
        );

        const aAttributeWeights = aInternalRepresentation?.attributes.map(
          (attribute) => fields.indexOf(attribute as F)
        ) ?? [Infinity];

        const bAttributeWeights = bInternalRepresentation?.attributes.map(
          (attribute) => fields.indexOf(attribute as F)
        ) ?? [Infinity];

        return Math.min(...aAttributeWeights) - Math.min(...bAttributeWeights);
      })

      // Highlighting
      .map((item) => {
        const highlights = {} as Record<keyof O, string>;

        if (!options.highlight) {
          return {
            ...item,
            _searchMeta: { highlights },
          };
        }

        for (const field of fields) {
          const value = item[field];
          if (typeof value === "string") {
            const shouldTruncate = Array.isArray(options.truncate)
              ? options.truncate.includes(field)
              : options.truncate;

            let truncatedValue = value as string;

            if (shouldTruncate) {
              truncatedValue = truncate(
                value,
                query,
                options.truncateLength ?? DEFAULT_TRUNCATE_LENGTH
              );
            }

            highlights[field as keyof O] = highlight(truncatedValue, query);
          }
        }

        return {
          ...item,
          _searchMeta: { highlights },
        };
      });

    if (options.caching) {
      cache.set(query, results);
    }

    return results;
  };

  return {
    index,
    search,
  };
};
