import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo, useState } from "react";
import { ApiQueryKeys, QueryResults } from "../../../api";
import {
  DBTranscription,
  DBTranscriptionVisibility,
  TranscriptionUpdateInput,
  TranscriptionUpdateResult,
} from "../../../api/transcriptions";
import { routes } from "../../../config/routes";
import NotFound from "../../../errors/NotFound";
import CopiedIcon from "../../../icons/CopiedIcon";
import CopyIcon from "../../../icons/CopyIcon";
import ShareIcon from "../../../icons/ShareIcon";
import useOverridableState from "../../../lib/hooks/useOverridableState";
import { useModal } from "../../providers/ModalProvider";
import { useMutation } from "../../providers/QueryProvider";
import Button from "../../shared/Button";
import Input from "../../shared/Input";
import Dots from "../../shared/loader/dots/Dots";

// - Utilities

const getSharingLink = (dbTranscription: DBTranscription) => {
  if (dbTranscription.visibility === DBTranscriptionVisibility.Private) {
    return undefined;
  }

  if (!dbTranscription.sharing_jwt) {
    return undefined;
  }

  return `${window.location.origin}/${routes.transcriptionPublic(
    dbTranscription.uuid
  )}?token=${dbTranscription.sharing_jwt}`;
};

// - Modal

enum ShareTranscriptModalViewState {
  Share = "share",
  CopyLink = "copy-link",
}

const ShareTranscriptModalView = ({
  data: { sharingLink, visibility },
  state: { state, loading = false, copied = false },
  callbacks: { onClickShare, onClickCopy, onClickMakePrivate, onClose },
}: {
  data: {
    sharingLink?: string;
    visibility: DBTranscriptionVisibility;
  };
  state: {
    state: ShareTranscriptModalViewState;
    loading?: boolean;
    copied?: boolean;
  };
  callbacks: {
    onClose: () => void;
    onClickShare: () => void;
    onClickMakePrivate: () => void;
    onClickCopy: () => void;
  };
}) => {
  const visibilityText = useMemo(() => {
    if (visibility === DBTranscriptionVisibility.Private) {
      return "private";
    }

    return "public";
  }, [visibility]);

  return (
    <div className="flex flex-col">
      <p>
        Your transcription is currently <strong>{visibilityText}</strong>.
      </p>
      {state === ShareTranscriptModalViewState.CopyLink ? (
        <div className="mt-8 mb-4 flex flex-col items-center justify-center">
          <div className="relative flex w-full">
            <Input
              disabled
              readOnly
              value={sharingLink}
              onClick={onClickCopy}
              className="w-full pr-20"
            />
            <span className="absolute right-0">
              <Button onClick={onClickCopy}>
                {copied ? <CopiedIcon /> : <CopyIcon />}
              </Button>
            </span>
          </div>
          <div className="mt-8 flex">
            <Button
              className="mr-2 border-yellow-600 text-yellow-600"
              onClick={onClickMakePrivate}
            >
              Make Private
            </Button>
            <Button onClick={onClose}>Close</Button>
          </div>
        </div>
      ) : (
        <div className="mt-16 mb-4 flex items-center justify-center">
          <Button
            onClick={onClickShare}
            disabled={loading}
            className="mr-2 border-yellow-600 text-yellow-600"
          >
            {loading ? "Loading..." : "Share"}
          </Button>
          <Button onClick={onClose}>Close</Button>
        </div>
      )}
    </div>
  );
};

const ShareTranscriptModal = ({ uuid }: { uuid: string }) => {
  const { closeModal } = useModal();

  const dbTranscription = useQuery<QueryResults[ApiQueryKeys.TranscriptionGet]>(
    [
      ApiQueryKeys.TranscriptionGet,
      {
        uuid,
      },
    ]
  );

  const [state, setState] = useOverridableState<ShareTranscriptModalViewState>(
    dbTranscription.data?.visibility === DBTranscriptionVisibility.Private
      ? ShareTranscriptModalViewState.Share
      : ShareTranscriptModalViewState.CopyLink
  );

  const [sharingLink, setSharingLink] = useOverridableState(
    dbTranscription.data?.sharing_jwt
      ? getSharingLink(dbTranscription.data)
      : undefined
  );

  const [copied, setCopied] = useState(false);

  const queryClient = useQueryClient();

  const { isLoading, mutateAsync } = useMutation<
    TranscriptionUpdateInput,
    TranscriptionUpdateResult
  >([ApiQueryKeys.TranscriptionUpdate, { uuid }, "PATCH"], {
    onSuccess: () => {
      queryClient.invalidateQueries([ApiQueryKeys.TranscriptionsGet]);
      queryClient.invalidateQueries([ApiQueryKeys.TranscriptionGet, { uuid }]);
    },
  });

  const onClickShare = useCallback(async () => {
    const { transcription } = await mutateAsync({
      visibility: DBTranscriptionVisibility.PublicWithLink,
    });

    setSharingLink(getSharingLink(transcription));
    setState(ShareTranscriptModalViewState.CopyLink);
  }, [mutateAsync, setSharingLink, setState]);

  const onClickMakePrivate = useCallback(async () => {
    await mutateAsync({
      visibility: DBTranscriptionVisibility.Private,
    });

    setSharingLink(undefined);
    setState(ShareTranscriptModalViewState.Share);
  }, [mutateAsync, setSharingLink, setState]);

  const onClickCopy = useCallback(() => {
    if (!sharingLink) {
      return;
    }

    navigator.clipboard.writeText(sharingLink);

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, [sharingLink]);

  if (dbTranscription.isLoading) {
    return (
      <div className="mt-10 flex justify-center">
        <div className="text-center">
          <Dots color="white" />
          <p className="transcription-loader-text mt-10">Loading</p>
        </div>
      </div>
    );
  }

  if (!dbTranscription.data) {
    // shouldn't reach here
    throw new NotFound();
  }

  return (
    <ShareTranscriptModalView
      data={{
        sharingLink,
        visibility: dbTranscription.data.visibility,
      }}
      state={{
        state,
        loading: isLoading,
        copied,
      }}
      callbacks={{
        onClickShare,
        onClickCopy,
        onClickMakePrivate,
        onClose: closeModal,
      }}
    />
  );
};

// - View

const ShareTranscriptView = ({ onClick }: { onClick: () => void }) => (
  <button onClick={onClick} className="transition-opacity hover:opacity-30">
    <i title="Share Transcription" test-id="share-transcript-button">
      <ShareIcon />
    </i>
  </button>
);

// - Default export

const ShareTranscript = ({ uuid }: { uuid: string }) => {
  const { openModal } = useModal();

  const onClick = useCallback(() => {
    openModal({
      title: "Share Transcription",
      content: <ShareTranscriptModal uuid={uuid} />,
    });
  }, [openModal, uuid]);

  return <ShareTranscriptView onClick={onClick} />;
};

export default ShareTranscript;
