import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import SecondaryButton from "../components/shared/buttons/SecondaryButton";
import SignIn from "../icons/SignIn";
import Dots from "../components/shared/loader/dots/Dots";
import { routes } from "../config/routes";

const Login = () => {
  const navigate = useNavigate();
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      navigate(routes.newTranscription);
    }
  }, [isAuthenticated, navigate]);

  if (isLoading) {
    return (
      <div className="mt-10 flex justify-center">
        <div className="text-center">
          <Dots color="white" />
          <p className="transcription-loader-text mt-10">Loading</p>
        </div>
      </div>
    );
  }

  return (
    <div className="container m-auto mt-10 w-5/6">
      <main className="">
        <div className="container flex flex-col md:flex-row">
          <div className="container px-8">
            <h2 className="my-4 text-3xl">Welcome to Verbalize.</h2>
            <p className="my-6 text-2xl">
              Verbalize is the universal transcriber for your meetings and
              media.
            </p>
            <p className="my-2 text-2xl">Please sign in to get started.</p>
            <div className="button-container my-12 text-center sm:flex">
              <SecondaryButton
                type="button"
                className="mx-auto w-48 rounded-md border-mainPurple bg-darkBlue-1 py-2 px-4 text-lg hover:border-mainPurpleDarkened "
                onClick={() =>
                  loginWithRedirect({
                    appState: location.pathname,
                    audience: import.meta.env.VITE_ENV,
                  })
                }
              >
                <SignIn />
                Sign In
              </SecondaryButton>
              {/* <SecondaryButton
                className="mx-auto mt-4 w-48 rounded-md border-mainBlue bg-darkBlue-1 py-2  px-4 text-lg hover:border-mainBlueDarkened sm:mt-0   sm:ml-8"
                onClick={() => {
                  window.location.href = "https://lemm.ai/subscriptions";
                }}
              >
                <ShoppingCart />
                Subscribe
              </SecondaryButton> */}
            </div>
          </div>
          <div className="container text-center">
            <img className="m-auto text-center" src="/verbalize_promo.png" />
          </div>
        </div>
      </main>
    </div>
  );
};

export default Login;
