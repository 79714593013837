import { useCallback } from "react";
import { Transcription } from "../../../api/transcriptions";
import { convertHMS } from "../../../lib/utils";

// @todo unit test
export const transcriptionToSrt = (transcription: Transcription): string => {
  let srt = "";

  for (let i = 0; i < transcription.length; i++) {
    srt += i + 1 + "\n";

    const startTime = convertHMS(transcription[i].start, true);
    const endTime = convertHMS(transcription[i].end, true);
    srt += startTime + " --> " + endTime + "\n";

    const segmentsText = transcription[i].segments.map(
      (s: { text: string }) => s.text
    );

    srt += segmentsText.join(" ") + "\n\n";
  }

  return srt;
};

// @todo unit test and extract
export const usePhantomDownload = () =>
  useCallback(
    (
      titleWithExtension: string,
      contentOrUrl: string,
      {
        type = "text/plain;charset=utf-8",
        isUrl = false,
      }: { type: string; isUrl: boolean } = {
        type: "text/plain;charset=utf-8",
        isUrl: false,
      }
    ) => {
      const element = document.createElement("a");

      element.href = isUrl
        ? contentOrUrl
        : URL.createObjectURL(new Blob([contentOrUrl], { type }));

      element.download = titleWithExtension;

      document.body.appendChild(element);
      element.click();
    },
    []
  );
