import React, { useCallback } from "react";

import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useUserHasSubscription } from "../../components/providers/SubscriptionProvider";

import Loader from "../../components/shared/loader/Loader";
import VerbalizeLogo from "../../icons/VerbalizeLogo";
import SecondaryButton from "../../components/shared/buttons/SecondaryButton";
import ShoppingCart from "../../icons/ShoppingCart";
import GearsMoveIcon from "../../icons/GearsMoveIcon";
import CheckmarkIcon from "../../icons/CheckmarkIcon";
import { ApiQueryKeys } from "../../api";
import {
  CheckoutSessionCreateInput,
  CheckoutSessionCreateResult,
  PortalSessionCreateResult,
} from "../../api/subscriptions";
import { useMutation } from "../../components/providers/QueryProvider";

const Subscriptions = () => {
  const hasSubscription = useUserHasSubscription();

  const { mutateAsync: mutateSubscriptionSession } = useMutation<
    CheckoutSessionCreateInput,
    CheckoutSessionCreateResult
  >([ApiQueryKeys.CheckoutSessionCreate]);

  const { mutateAsync: mutatePortalSession } = useMutation<
    object,
    PortalSessionCreateResult
  >([ApiQueryKeys.PortalSessionCreate]);

  const onManageSubscriptionClick = useCallback(async () => {
    const { url } = await mutatePortalSession({});
    if (url) {
      window.location.href = url;
    }
  }, [mutatePortalSession]);

  const onSubscriptionClick = useCallback(async () => {
    const { url } = await mutateSubscriptionSession({
      lookup_key: "meeting_notes",
    });
    if (url) {
      window.location.href = url;
    }
  }, [mutateSubscriptionSession]);

  return (
    <SubscriptionView
      isSubscribed={hasSubscription}
      onManageSubscriptionClick={onManageSubscriptionClick}
      onSubscriptionClick={onSubscriptionClick}
    />
  );
};

const SubscriptionView = ({
  isSubscribed,
  onManageSubscriptionClick,
  onSubscriptionClick,
}: {
  isSubscribed: boolean;
  onManageSubscriptionClick: () => void;
  onSubscriptionClick: () => void;
}) => (
  <div className="container mt-4 px-4">
    <h2 className="text-xl font-bold">SUBSCRIPTIONS</h2>
    <div className="mt-8 flex justify-center">
      <div className="w-96 rounded-md border border-darkBlue-5 bg-darkBlue-2 px-10 py-2">
        <h3 className="mt-4 text-center text-xl font-medium">
          Verbalize Subscription
        </h3>
        <p className="mt-8 flex justify-center text-center">
          <VerbalizeLogo size={55} />
        </p>
        <p className="mt-12">
          Verbalize, a universal transcriber for your meetings and media.
        </p>
        <p className="mt-12 font-bold">$14,99 per month</p>
        <p className="mt-12">
          {isSubscribed ? (
            <SecondaryButton
              className="m-auto w-64 border-0 bg-mainPurple hover:bg-mainPurpleDarkened"
              onClick={() => {
                onManageSubscriptionClick();
              }}
            >
              <GearsMoveIcon /> Manage Subscription
            </SecondaryButton>
          ) : (
            <SecondaryButton
              className="m-auto w-64 border-0 bg-mainBlue hover:bg-mainBlueDarkened"
              onClick={() => {
                onSubscriptionClick();
              }}
            >
              <ShoppingCart color="#e5e5e5" /> Subscribe To Verbalize
            </SecondaryButton>
          )}
        </p>

        <p className="mt-8 h-8">
          {isSubscribed && (
            <p className="flex">
              <CheckmarkIcon />{" "}
              <span className="relative bottom-0.5 left-3">
                Already subscribed
              </span>
            </p>
          )}
        </p>
      </div>
    </div>
  </div>
);

export default withAuthenticationRequired(Subscriptions, {
  onRedirecting: () => <Loader color="white" />,
});
