import { StrictMode } from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import Auth0ProviderWithHistory from "./components/providers/Auth0ProviderWithHistory";

import App from "./App";

import "./scss/root.scss"; // This is just for some default body styles and resets.
import "./index.css";

import QueryProvider from "./components/providers/QueryProvider";
import ModalProvider from "./components/providers/ModalProvider";
import { MediaPlayerProvider } from "./components/providers/MediaPlayerProvider";
import { LanguageProvider } from "./components/providers/LanguageProvider";
import { ErrorProvider } from "./components/providers/ErrorProvider";

Sentry.init({
  dsn:
    import.meta.env.VITE_SENTRY_URL ||
    "https://0e567a83823b4e488bb9f32792c04453@o4504571716829184.ingest.sentry.io/4504639497306112",
  integrations: [new BrowserTracing()],
  tracesSampleRate: import.meta.env.VITE_APP_ENV === "production" ? 0.2 : 1.0,
  environment: import.meta.env.VITE_APP_ENV,
});

const Main = () => (
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <ErrorProvider>
        <QueryProvider>
          <ModalProvider>
            <MediaPlayerProvider>
              <LanguageProvider>
                <App />
                <ReactQueryDevtools initialIsOpen={false} />
              </LanguageProvider>
            </MediaPlayerProvider>
          </ModalProvider>
        </QueryProvider>
      </ErrorProvider>
    </Auth0ProviderWithHistory>
  </BrowserRouter>
);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <Main />
  </StrictMode>
);
