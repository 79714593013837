import NotFound from "../../errors/NotFound";
import NotFoundView from "./NotFound";

const ExceptionView = ({ error }: { error: Error }) => {
  if (error instanceof NotFound) {
    return <NotFoundView />;
  }

  return (
    <div className="container mx-auto mt-8">
      <h2 className="mb-3 text-xl">Something went wrong!</h2>
      {"status" in error && <p>Error {`${error.status}`}</p>}
      <p>
        <pre>
          <code>{error.message}</code>
        </pre>
      </p>
    </div>
  );
};

export default ExceptionView;
