import cx from "classnames";
import { ButtonHTMLAttributes, PropsWithChildren } from "react";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const SecondaryButton = ({
  children,
  className,
  ...props
}: PropsWithChildren<ButtonProps>) => (
  <button
    {...props}
    className={cx(
      "sm:1/4 flex h-12 items-center rounded-md border px-4 py-2 indent-4 text-sm font-bold transition-all ",
      className
    )}
  >
    {children}
  </button>
);

export default SecondaryButton;
