import React from "react";

const Legal = () => (
  <div className="container mt-4 px-4">
    <h2 className="text-xl font-bold">Legal documentation</h2>
    <div>
      <ul className="ml-4 mt-6 list-disc">
        <li className="mb-4">
          <a
            href="/assets/documents/California Resident Privacy Notice - Feb 8 2023.pdf"
            className="underline hover:no-underline"
          >
            California Resident Privacy Notice - Feb 8 2023
          </a>
        </li>
        <li className="mb-4">
          <a
            href="/assets/documents/Privacy Policy - Verbalize - Feb 8 2023.pdf"
            className="underline hover:no-underline"
          >
            Privacy Policy - Verbalize - Feb 8 2023
          </a>
        </li>
        <li className="mb-4">
          <a
            href="/assets/documents/Verbalize Data Request Policy - Feb 8 2023.pdf"
            className="underline hover:no-underline"
          >
            Verbalize Data Request Policy - Feb 8 2023
          </a>
        </li>
        <li className="mb-4">
          <a
            href="/assets/documents/Verbalize Subprocessor List .pdf"
            className="underline hover:no-underline"
          >
            Verbalize Subprocessor List
          </a>
        </li>
        <li className="mb-4">
          <a
            href="/assets/documents/Verbalize Terms of Use .pdf"
            className="underline hover:no-underline"
          >
            Verbalize Terms of Use
          </a>
        </li>
      </ul>
    </div>
  </div>
);

export default Legal;
