import { useCallback, useState } from "react";
import {
  DBTranscription,
  TranscriptionCreateInput,
} from "../../../../api/transcriptions";
import RecordingStartIcon from "../../../../icons/RecordingStartIcon";
import RecordingStopIcon from "../../../../icons/RecordingStopIcon";
import useRecorder from "../../../../lib/hooks/useRecorder";
import { convertHMS, readFileAsync } from "../../../../lib/utils";
import { UseMutationResult } from "@tanstack/react-query";
import { useLanguage } from "../../../providers/LanguageProvider";

// - Types

export interface RecordingViewProps {
  state: {
    isRecording: boolean;
    recordingTime: number;
  };
  actions: {
    onClickStart: () => void;
    onClickStop: () => void;
  };
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type RecordingProps = {};

// - View

const RecordingView = ({
  state: { isRecording, recordingTime },
  actions: { onClickStart, onClickStop },
}: RecordingViewProps) => (
  <div className="flex w-full flex-col items-center">
    <div className="my-4 w-full text-center text-xl font-thin">
      {isRecording
        ? "Audio recording in progress! Press the button below to stop and generate transcript."
        : "Record audio by clicking on the button below"}
    </div>
    <div className="my-4 flex w-80 justify-center">
      <i
        className="cursor-pointer"
        onClick={isRecording ? onClickStop : onClickStart}
        test-id={
          isRecording ? "stop-recording-button" : "start-recording-button"
        }
      >
        {isRecording ? <RecordingStopIcon /> : <RecordingStartIcon />}
      </i>
    </div>
    <div className="recording-timer">
      {isRecording && recordingTime ? convertHMS(recordingTime) : null}
    </div>
  </div>
);

// - Default export

const Recording = ({
  createTranscriptionMutation,
}: {
  createTranscriptionMutation: UseMutationResult<
    DBTranscription,
    unknown,
    TranscriptionCreateInput,
    unknown
  >;
}) => {
  const { language } = useLanguage();

  const onStop = useCallback(
    async (blob: Blob) => {
      const file = await readFileAsync(blob);

      const title = "Voice Note";

      await createTranscriptionMutation.mutateAsync({
        title,
        language,
        word_timestamps: true,
        diarize: true,
        file,
      });
    },
    [createTranscriptionMutation, language]
  );

  const { initializeRecorder, recordingTime, isRecording } = useRecorder({
    onStop,
  });

  // - State

  const [audioRecorder, setAudioRecorder] = useState<MediaRecorder>();

  // - Actions

  const onClickStart = useCallback(async () => {
    const recorder = await initializeRecorder();
    setAudioRecorder(recorder);

    if (recorder) {
      recorder.start();
    } else {
      console.error("something went wrong with initialising audio recorder.");
    }
  }, [initializeRecorder]);

  const onClickStop = useCallback(() => {
    if (audioRecorder) {
      audioRecorder.stop();
    }
  }, [audioRecorder]);

  // - Render

  return (
    <RecordingView
      state={{
        isRecording,
        recordingTime,
      }}
      actions={{
        onClickStart,
        onClickStop,
      }}
    />
  );
};

export default Recording;
