import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useEnsureUserHasAccess } from "../../components/providers/SubscriptionProvider";
import { PropsWithChildren, useCallback, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../../components/shared/loader/Loader";
import {
  DBTranscription,
  TranscriptionCreateInput,
  TranscriptionType,
} from "../../api/transcriptions";
import Dashboard from "../../components/views/NewTranscription/TranscriptionType/Dashboard";
import FileUpload from "../../components/views/NewTranscription/TranscriptionType/FileUpload";
import Recording from "../../components/views/NewTranscription/TranscriptionType/Recording";
import Url from "../../components/views/NewTranscription/TranscriptionType/Url";
import { useNavigate, useParams } from "react-router-dom";
import { routes } from "../../config/routes";
import LeftArrow from "../../icons/LeftArrow";
import { UseMutationResult, useQueryClient } from "@tanstack/react-query";
import { ApiQueryKeys, MutationResults } from "../../api";
import { useError } from "../../components/providers/ErrorProvider";
import { supportedLanguages } from "../../lib/utils";
import { useLanguage } from "../../components/providers/LanguageProvider";
import LanguageDropdown from "../../components/shared/languageDropdown/languageDropdown";
import { useMutation } from "../../components/providers/QueryProvider";
import Progress from "../../components/shared/Progress";

// - Types

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface NewTranscriptionProps {}

export interface NewTranscriptionViewProps {
  computed: {
    transcriptionType: TranscriptionType | undefined;
    userName: string;
  };
  callbacks: {
    navigate: (path: string) => void;
  };
  actions: {
    onClickBack: () => void;
  };
  mutations: {
    createTranscriptionMutation: UseMutationResult<
      DBTranscription,
      unknown,
      TranscriptionCreateInput,
      unknown
    >;
  };
}

// - New Transcription Wrapper

const NewTranscriptionWrapper = ({
  children,
  onClickBack,
}: PropsWithChildren<{
  onClickBack: () => void;
}>) => {
  const { language, setLanguage } = useLanguage();

  return (
    <div className="mt-8">
      <div className="flex flex-col">
        <div className="flex justify-between">
          <div className="mb-8">
            <a
              href="#"
              onClick={onClickBack}
              className="flex w-full items-center hover:opacity-50"
            >
              <i className="mr-2">
                <LeftArrow />
              </i>
              Back
            </a>
          </div>
          <div className="flex flex-col sm:block">
            <span className="sb:mb-0 mr-2 mb-4 text-sm font-light">
              Transcription language
            </span>
            <LanguageDropdown
              selectedLanguage={language}
              languages={supportedLanguages}
              setSelectedLanguage={setLanguage}
            />
          </div>
        </div>

        {children}
      </div>
    </div>
  );
};

// - View

export const NewTranscriptionView = ({
  computed: { transcriptionType, userName },
  callbacks: { navigate },
  actions: { onClickBack },
  mutations: { createTranscriptionMutation },
}: NewTranscriptionViewProps) => (
  <div>
    {!transcriptionType ? (
      <Dashboard
        userName={userName}
        onClickURL={() => navigate(routes.newTranscriptionUrl)}
        onClickFileUpload={() => navigate(routes.newTranscriptionUpload)}
        onClickRecord={() => {
          navigate(routes.newTranscriptionRecording);
        }}
      />
    ) : null}

    {transcriptionType === TranscriptionType.Url && (
      <NewTranscriptionWrapper onClickBack={onClickBack}>
        <Url createTranscriptionMutation={createTranscriptionMutation} />
      </NewTranscriptionWrapper>
    )}

    {transcriptionType === TranscriptionType.Recording && (
      <NewTranscriptionWrapper onClickBack={onClickBack}>
        <Recording createTranscriptionMutation={createTranscriptionMutation} />
      </NewTranscriptionWrapper>
    )}

    {transcriptionType === TranscriptionType.FileUpload && (
      <NewTranscriptionWrapper onClickBack={onClickBack}>
        <FileUpload createTranscriptionMutation={createTranscriptionMutation} />
      </NewTranscriptionWrapper>
    )}
  </div>
);

// - Default export

// eslint-disable-next-line no-empty-pattern
const NewTranscription = ({}: NewTranscriptionProps) => {
  useEnsureUserHasAccess();

  const navigate = useNavigate();
  const { user } = useAuth0();
  const { type: transcriptionType } = useParams<{
    type: TranscriptionType | undefined;
  }>();

  // - Mutation

  const [loading, setLoading] = useState(false);
  const [progressPercent, setProgressPercent] = useState(0);

  const [, setError] = useError();

  const queryClient = useQueryClient();

  const createTranscriptionMutation = useMutation<
    TranscriptionCreateInput,
    MutationResults[ApiQueryKeys.TranscriptionsCreate]
  >(
    [ApiQueryKeys.TranscriptionsCreate, {}, "PUT"],
    {
      onMutate: () => {
        setLoading(true);
      },
      onSuccess: async (result) => {
        await queryClient.invalidateQueries({
          queryKey: [ApiQueryKeys.TranscriptionsGet],
        });

        await queryClient.invalidateQueries({
          queryKey: [ApiQueryKeys.TranscriptionGet, result.uuid],
        });

        navigate(routes.transcribe(result.uuid));
      },
      onError: (e) => {
        setError(
          <div>
            <p>Something went wrong. Please try again later.</p>
            <p className="my-4 font-mono text-xs">
              Error: {(e as Error).message}
            </p>
          </div>
        );
      },
      onSettled: () => {
        setLoading(false);
      },
    },
    {
      onUploadProgress: (progressEvent) => {
        setProgressPercent(Math.round((progressEvent.progress ?? 0) * 100));
      },
    }
  );

  // - Actions

  const onClickBack = useCallback(() => {
    navigate(routes.newTranscription);
  }, [navigate]);

  // - Render

  if (loading) {
    return (
      <div className="mt-10 flex justify-center">
        <div className="text-center">
          <Progress percent={progressPercent} />

          <p className="transcription-loader-text mt-10">Uploading media...</p>
        </div>
      </div>
    );
  }

  return (
    <NewTranscriptionView
      computed={{
        transcriptionType,
        userName: user?.given_name || "friend",
      }}
      callbacks={{
        navigate,
      }}
      actions={{
        onClickBack,
      }}
      mutations={{
        createTranscriptionMutation,
      }}
    />
  );
};

export default withAuthenticationRequired(NewTranscription, {
  onRedirecting: () => <Loader color="white" />,
});
