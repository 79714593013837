import cx from "classnames";
import style from "./Dots.module.scss";

type DotsProps = {
  color?: "white";
  size?: "small" | "default";
  className?: string;
};

const Dots = ({ size = "default", className }: DotsProps) => (
  <div
    className={cx(
      "relative inline-block",
      style.dotsLoader,
      size === "small" ? "w-[40px]" : "w-[80px]",
      className
    )}
  >
    <Dot size={size} />
    <Dot size={size} />
    <Dot size={size} />
    <Dot size={size} />
  </div>
);

const Dot = ({ size }: DotsProps) => (
  <div
    style={{ animationTimingFunction: "cubic-bezier(0, 1, 1, 0)" }}
    className={cx(
      "absolute rounded-full bg-white",
      size === "small" ? "small h-[6px] w-[6px]" : "h-[13px] w-[13px]"
    )}
  ></div>
);

export default Dots;
