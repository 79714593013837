import { useEffect } from "react";

const INJECTED_DIV_ID = "injected-feedback-button";

export const useFeedbackButton = () => {
  useEffect(() => {
    if (document.getElementById(INJECTED_DIV_ID)) return;

    const token = import.meta.env.VITE_FEEDBACK_BUTTON_TOKEN;

    if (!token) {
      console.log(
        "Feedback button token not found. Please add VITE_FEEDBACK_BUTTON_TOKEN to your .env file."
      );
      return;
    }

    const script = document.createElement("script");
    script.src = "https://feedbackbutton.io/script.js";
    script.setAttribute("data-token", token);
    script.setAttribute("defer", "");
    script.setAttribute("id", INJECTED_DIV_ID);
    document.head.appendChild(script);
  }, []);
};
