const TelevisionIcon = () => (
  <svg
    width="52"
    height="52"
    viewBox="0 0 17 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className="si-glyph si-glyph-television"
  >
    <title>981</title>

    <defs></defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M15.381,5.06 L9.468,5.06 L12.207,1.27 L11.195,1.047 L8.303,5.06 L8.295,5.06 L5.403,1.047 L4.389,1.27 L7.128,5.06 L0.656,5.06 C0.328,5.06 0.063,5.315 0.063,5.631 L0.063,16.416 C0.063,16.733 0.328,16.988 0.656,16.988 L15.379,16.988 C15.708,16.988 15.973,16.733 15.973,16.416 L15.973,5.631 C15.975,5.314 15.71,5.06 15.381,5.06 L15.381,5.06 Z M13,14.67 C13,14.876 12.848,15.042 12.658,15.042 L2.263,15.042 C2.075,15.042 1.922,14.876 1.922,14.67 L1.922,7.304 C1.922,7.1 2.074,6.932 2.263,6.932 L12.658,6.932 C12.847,6.932 13,7.1 13,7.304 L13,14.67 L13,14.67 Z M15,15.042 L14,15.042 L14,14 L15,14 L15,15.042 L15,15.042 Z"
        fill="white"
        className="si-glyph-fill"
      ></path>
    </g>
  </svg>
);

export default TelevisionIcon;
