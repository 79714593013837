import { useEffect, useMemo } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loader from "../../components/shared/loader/Loader";
import ViewTranscription from "../../components/views/Transcription/ViewTranscription";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { QueryResults, ApiQueryKeys } from "../../api";
import {
  chunkedBySequentialSpeaker,
  diarizeTranscription,
} from "../../lib/diarization";
import {
  DBTranscriptionStatus,
  TranscriptionEventDiarization,
  TranscriptionEventName,
  TranscriptionEventNewSegment,
} from "../../api/transcriptions";
import { QueryError } from "../../components/providers/QueryProvider";
import NotFound from "../../errors/NotFound";

const POLLING_INTERVAL = 1000;

// - Default export

const Transcription = () => {
  const { uuid } = useParams();

  // - Query transcription

  const dbTranscription = useQuery<
    QueryResults[ApiQueryKeys.TranscriptionGet],
    QueryError
  >([
    ApiQueryKeys.TranscriptionGet,
    {
      uuid,
    },
  ]);

  const loading = useMemo(
    () => dbTranscription.isLoading,
    [dbTranscription.isLoading]
  );

  const transcription = useMemo(() => {
    const events = dbTranscription.data?.content.events ?? [];

    const segments = events
      .filter(
        (e): e is TranscriptionEventNewSegment =>
          e.event === TranscriptionEventName.NewSegment
      )
      .flatMap((e) => e.data.segments);

    const diarizationInfo =
      events.find(
        (event): event is TranscriptionEventDiarization =>
          event.event === TranscriptionEventName.Diarization
      )?.data.labels ?? [];

    const transcription = chunkedBySequentialSpeaker(segments);

    const transcriptionWithMetadata = {
      uuid: dbTranscription.data?.uuid || uuid || "",
      transcription,
      diarizationInfo,
      created_at: dbTranscription.data?.created_at || "",
      dbTranscription: dbTranscription.data,
    };

    return diarizeTranscription({
      ...transcriptionWithMetadata,
      diarizationInfo,
    });
  }, [dbTranscription.data, uuid]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;

    const triggerInterval = () => {
      interval = setInterval(() => {
        if (
          dbTranscription.data?.status !== DBTranscriptionStatus.Complete ||
          Object.keys(dbTranscription.data?.transcription_metadata || {})
            .length > 0
        ) {
          clearInterval(interval);
          return;
        }

        dbTranscription.refetch();
      }, POLLING_INTERVAL);
    };

    if (
      dbTranscription.data?.status === DBTranscriptionStatus.Complete &&
      Object.keys(dbTranscription.data?.transcription_metadata || {}).length ===
        0
    ) {
      triggerInterval();
    }

    return () => clearInterval(interval);
  }, [dbTranscription]);

  if (
    dbTranscription.error?.status === 404 ||
    dbTranscription.error?.status === 422 ||
    dbTranscription.failureReason?.status === 404
  ) {
    throw new NotFound();
  }

  return (
    <ViewTranscription
      state={{
        loading,
        transcription,
      }}
    />
  );
};

export default withAuthenticationRequired(Transcription, {
  onRedirecting: () => <Loader color="white" />,
});
