import cx from "classnames";

import Button from "../../Button";
import ErrorIcon from "../../../../icons/ErrorIcon";
import CloseIcon from "../../../../icons/CloseIcon";

import style from "./Error.module.scss";

const ErrorPopup = (
  {
    message = "",
    close,
  }: {
    message?: React.ReactNode;
    close: () => void;
  } // TODO: switch to tailwind
) => (
  <div className={style.wrapper}>
    <div className={style.content}>
      <div className={style.textContent}>
        <div className={style.header}>
          <span className={style.errorIcon}>
            <ErrorIcon />
          </span>
          Error
        </div>
        <div className={style.text}>{message}</div>
      </div>
      <div className={style.buttonContainer}>
        <Button
          className={cx("button button-outline error")}
          onClick={() => close()}
        >
          Close
        </Button>
      </div>
      <div
        data-testid="close-button"
        className={style.closeIcon}
        onClick={() => close()}
      >
        <CloseIcon />
      </div>
    </div>
  </div>
);

export default ErrorPopup;
