import LanguageDropdown from "../../../shared/languageDropdown/languageDropdown";
import RecordingStartIcon from "../../../../icons/RecordingStartIcon";
import Upload2Icon from "../../../../icons/Upload2Icon";
import URLIcon from "../../../../icons/URLIcon";
import PrimaryButton from "../../../shared/buttons/PrimaryButton";
import { supportedLanguages } from "../../../../lib/utils";
import { useLanguage } from "../../../providers/LanguageProvider";
import { TranscriptionLanguage } from "../../../../api/transcriptions";

// - Types

export interface DashboardProps {
  userName: string;
  onClickURL: () => void;
  onClickFileUpload: () => void;
  onClickRecord: () => void;
}

export interface DashboardViewProps {
  computed: {
    userName: string;
  };
  callbacks: {
    onClickURL: () => void;
    onClickFileUpload: () => void;
    onClickRecord: () => void;
  };
  state: {
    setLanguage: (language: TranscriptionLanguage) => void;
    language: TranscriptionLanguage;
  };
}

// - Styled components

const Dashboard = ({
  userName,
  onClickURL,
  onClickFileUpload,
  onClickRecord,
}: DashboardProps) => {
  const { language, setLanguage } = useLanguage();

  return (
    <DashboardView
      computed={{
        userName,
      }}
      callbacks={{
        onClickURL,
        onClickFileUpload,
        onClickRecord,
      }}
      state={{
        setLanguage,
        language,
      }}
    />
  );
};

// - View

export const DashboardView = ({
  computed: { userName },
  callbacks: { onClickURL, onClickFileUpload, onClickRecord },
  state: { setLanguage, language },
}: DashboardViewProps) => (
  <>
    <div className="mt-2 flex flex-col justify-between sm:mt-6 sm:flex-row">
      <div className="mt-2 mb-2 text-4xl sm:my-2">Good day, {userName}</div>
      <div className="my-2 sm:my-2">
        <span className="mr-2 text-sm font-light">Transcription language</span>
        {
          <LanguageDropdown
            languages={supportedLanguages}
            setSelectedLanguage={setLanguage}
            selectedLanguage={language}
          />
        }
      </div>
    </div>
    <div className="mb-2">
      Choose one of the options below to start transcribing.
    </div>
    <div className="my-8 flex flex-wrap justify-center sm:justify-start">
      <PrimaryButton onClick={onClickURL} classes="border-mainBlue">
        <div className="mr-4">
          <URLIcon color="#6384ff" />
        </div>
        <div className="text-left">
          <h5 className="mb-2 text-xl font-medium">YouTube/URL</h5>
          <p className="text-sm italic">
            Transcribe any link from YouTube, or any media file
          </p>
        </div>
      </PrimaryButton>
      <PrimaryButton onClick={onClickFileUpload} classes="border-mainPurple">
        <div className="mr-4">
          <Upload2Icon color="#9672d0" />
        </div>
        <div className="text-left">
          <h5 className="mb-2 text-xl font-medium">Upload Audio File</h5>
          <p className="text-sm italic">
            Supports mp3, mp4, m4a, wav, or ogg formats
          </p>
        </div>
      </PrimaryButton>
      <PrimaryButton classes="border-mainOrange" onClick={onClickRecord}>
        <div className="mr-4">
          <RecordingStartIcon color="#e8af5c" />
        </div>
        <div className="text-left">
          <h5 className="mb-2 text-xl font-medium">Record Audio</h5>
          <p className="text-sm italic">
            Use our audio recorder to transcribe live
          </p>
        </div>
      </PrimaryButton>
    </div>
    {/* <div className={"__OLD__-styles-mainText"}>
  Or do you want to search through your existing transcriptions?
</div>
<div className={"__OLD__-styles-mainText"}>
  Use our AI powered search tool in{" "}
  <span className="bold">My Transcriptions</span>.
</div> */}

    {/* <div className={"__OLD__-styles-buttonContainer"}>
  <button
    className={classNames(
      "meeting-notes-new-button",
      style.startButton
    )}
    test-id="my-transcriptions-button"
    onClick={() => {
      navigate("../my-transcriptions-page");
    }}
  >
    <ShrinkingVerticalLinesIcon />
    My Transcriptions
  </button>
</div> */}
  </>
);

// - Default export

export default Dashboard;
