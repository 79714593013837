/* eslint-disable @typescript-eslint/no-empty-function */

import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import { ApiQueryKeys } from "../../api";
import {
  FreeUsageGetResult,
  Product,
  SubscriptionGetResult,
} from "../../api/subscriptions";
import { subscribeUrl } from "../../config/api";
import useLoadingChain, {
  useSuccessfullyLoadedChain,
} from "../../lib/hooks/useLoadingChain";
import { routes } from "../../config/routes";

// - Types

export type SubscriptionContext = {
  loading: boolean;
  loaded: boolean;
  products: Product[];
  freeUsage: { [key: string]: number };
  refetchSubscription: () => void;
  refetchFreeUsage: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SubscriptionProviderProps {}

// - Context

export const SubscriptionContext = createContext<SubscriptionContext>({
  loading: false,
  loaded: false,
  products: [],
  freeUsage: {},
  refetchSubscription: () => {},
  refetchFreeUsage: () => {},
});

// - Provider

const SubscriptionProvider = ({
  children,
}: PropsWithChildren<SubscriptionProviderProps>) => {
  // - Check for subscription on mount

  const subscriptionQuery = useQuery<SubscriptionGetResult>([
    ApiQueryKeys.SubscriptionGet,
    {},
    "POST",
  ]);

  const freeUsageQuery = useQuery<FreeUsageGetResult>([
    ApiQueryKeys.FreeUsageGet,
    {},
    "POST",
  ]);

  // - Render

  const loading = useLoadingChain(subscriptionQuery, freeUsageQuery);
  const loaded = useSuccessfullyLoadedChain(subscriptionQuery, freeUsageQuery);

  const value = useMemo(
    () => ({
      loading,
      loaded,
      products: subscriptionQuery.data?.subscription ?? [],
      freeUsage: freeUsageQuery.data?.free_usage ?? {},
      refetchSubscription: subscriptionQuery.refetch,
      refetchFreeUsage: freeUsageQuery.refetch,
    }),
    [
      loading,
      loaded,
      subscriptionQuery.data?.subscription,
      subscriptionQuery.refetch,
      freeUsageQuery.data?.free_usage,
      freeUsageQuery.refetch,
    ]
  );

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionProvider;

// - Hooks

export const useSubscription = () => useContext(SubscriptionContext);

export const useUserHasSubscription = (
  product: Product = Product.MeetingNotes
) => {
  const { products } = useSubscription();

  return useMemo(() => products.includes(product), [product, products]);
};

export const useEnsureUserHasAccess = (
  product: Product = Product.MeetingNotes
) => {
  const { loaded, products, freeUsage } = useSubscription();
  const navigate = useNavigate();

  const hasAccess = useMemo(
    () =>
      products.includes(product) ||
      Object.values(freeUsage).reduce((sum, dayTotal) => sum + dayTotal, 0) > 0,
    [product, products, freeUsage]
  );

  useEffect(() => {
    if (loaded && !hasAccess) {
      window.location.href = subscribeUrl;
    }
  }, [hasAccess, loaded, navigate]);
};

// - Views

export const FreeUsageRemainingView = () => {
  const isSubscribed = useUserHasSubscription();
  const { freeUsage, loaded } = useSubscription();

  // @todo let's use a proper date library
  const today = useMemo(() => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
  }, []);

  const freeUsageToday = useMemo(
    () => freeUsage[today] ?? 0,
    [freeUsage, today]
  );

  if (isSubscribed || !loaded) {
    return null;
  }

  return (
    <div className="flex w-full justify-end">
      <div className="mt-2 mr-3 max-w-fit rounded-md bg-gray-900 px-3 py-1 text-xs font-light tracking-wide">
        You have {Math.round(freeUsageToday / 60)} free minutes remaining today.
        Please{" "}
        <Link
          to={routes.subscriptions}
          className="underline hover:no-underline"
        >
          subscribe
        </Link>
      </div>
    </div>
  );
};
