import cx from "classnames";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  TranscriptionWithMetadata,
  DiarizedTranscriptionChunk,
  TranscriptionChunk,
  MediaPlayerType,
  DiarizedTranscriptionWithMetadata,
  DBTranscriptionRequestType,
  DBTranscriptionStatus,
  ExtractedInfoPanelType,
  TranscriptionUpdateInput,
  DBTranscriptionVisibility,
  isPublicTranscription,
  PostTranscriptionData,
} from "../../../api/transcriptions";
import useHighlightTranscript from "../../../lib/hooks/useHighlightTranscription";
import {
  MediaPlayer,
  useMediaPlayer,
} from "../../providers/MediaPlayerProvider";
import Dots from "../../shared/loader/dots/Dots";
import DownloadTranscript from "./DownloadTranscript";
import { ApiQueryKeys } from "../../../api";
import useOverridableState from "../../../lib/hooks/useOverridableState";
import { useMutation } from "../../providers/QueryProvider";
import { useQueryClient } from "@tanstack/react-query";
import ExtendablePanel from "../../shared/panels/ExtendablePanel";
import Button from "../../shared/Button";
import Progress from "../../shared/Progress";
import ShareTranscript from "./ShareTranscript";
import GlobeIcon from "../../../icons/GlobeIcon";
import { useAuth0 } from "@auth0/auth0-react";

// - Types

export interface ViewTranscriptionViewProps {
  data: {
    uuid: TranscriptionWithMetadata["uuid"] | undefined;
    title: NonNullable<TranscriptionWithMetadata["dbTranscription"]>["title"];
    visibility: DBTranscriptionVisibility;
    normalizedTranscriptionChunks: (
      | DiarizedTranscriptionChunk
      | TranscriptionChunk
    )[];
    mediaPlayerType: MediaPlayerType;
    transcriptionMetadata: PostTranscriptionData;
  };
  state: {
    loading: boolean;
    progress?: number;
  };
  computed: {
    isCompleted: boolean;
  };
  actions: {
    onClickWord: (startTime: number) => void;
  };
  user: {
    isAuthor: boolean;
    name?: string;
  };
}

export interface ViewTranscriptionProps {
  state: {
    loading: boolean;
    transcription:
      | DiarizedTranscriptionWithMetadata
      | TranscriptionWithMetadata
      | undefined;
    progress?: number;
  };
}

// - Extracted Info

const ViewExtractedInfo = ({
  transcriptionMetadata,
  mediaPlayerType,
}: {
  transcriptionMetadata: PostTranscriptionData;
  mediaPlayerType: MediaPlayerType;
}) => {
  const [extractedPanelActive, setExtractedPanelActive] = useState<
    ExtractedInfoPanelType | ""
  >(ExtractedInfoPanelType.Notes);

  const loading = useMemo(
    () => Object.keys(transcriptionMetadata).length === 0,
    [transcriptionMetadata]
  );

  const hasLoadedRef = useRef(false);

  const {
    notes: extractedNotes,
    questions: extractedQuestions,
    summary: extractedSummary,
  } = useMemo(
    () => ({
      notes: transcriptionMetadata.notes || [],
      questions: transcriptionMetadata.questions || [],
      summary: transcriptionMetadata.summary || "",
    }),
    [
      transcriptionMetadata.notes,
      transcriptionMetadata.questions,
      transcriptionMetadata.summary,
    ]
  );

  useEffect(() => {
    if (hasLoadedRef.current) {
      return;
    }

    if (extractedSummary?.length > 0) {
      setExtractedPanelActive(ExtractedInfoPanelType.Summary);
    } else if (extractedNotes?.length > 0) {
      setExtractedPanelActive(ExtractedInfoPanelType.Notes);
    } else if (extractedQuestions?.length > 0) {
      setExtractedPanelActive(ExtractedInfoPanelType.Questions);
    }
  }, [extractedNotes, extractedQuestions, extractedSummary]);

  useEffect(() => {
    if (!loading) {
      hasLoadedRef.current = true;
    }
  }, [loading]);

  return (
    <div className="w-1/1 mr-4 ml-4 mt-4 flex flex-col justify-between md:pb-0 lg:mt-0 lg:w-1/3">
      <div
        className={`${
          mediaPlayerType === "video" ? "sm:h-[50vh]" : "sm:h-[60vh]"
        } h-auto pb-24`}
      >
        <div className="mt-1">
          <p
            className={`text-lg font-extralight text-white ${
              !extractedNotes?.length &&
              !extractedQuestions?.length &&
              !extractedSummary?.length
                ? "opacity-30"
                : ""
            }`}
          >
            Extracted Info
          </p>
          <p className="extracted-info-filter-panels"></p>
        </div>
        {/* <ExtendablePanel
          isOpen={extractedPanelActive === ExtractedInfoPanelType.Summary}
          onPanelClick={() => {
            setExtractedPanelActive(
              extractedPanelActive === ExtractedInfoPanelType.Summary
                ? ""
                : ExtractedInfoPanelType.Summary
            );
          }}
          content={
            !!extractedSummary && (
              <p className="border border-darkBlue-4 py-2 px-4">
                {extractedSummary}
              </p>
            )
          }
          title="SUMMARY"
        /> */}
        <ExtendablePanel
          isOpen={extractedPanelActive === ExtractedInfoPanelType.Notes}
          onPanelClick={() => {
            setExtractedPanelActive(
              extractedPanelActive === ExtractedInfoPanelType.Notes
                ? ""
                : ExtractedInfoPanelType.Notes
            );
          }}
          content={
            loading ? (
              <p className="flex w-full justify-center pt-6">
                <Dots />
              </p>
            ) : extractedNotes.length === 0 ? (
              <p className="w-full p-6 text-center">No notes</p>
            ) : (
              extractedNotes?.map((item: string, index: number) => (
                <p
                  className="border border-darkBlue-4 py-2 px-4 last:border-0"
                  key={index}
                >
                  {item}
                </p>
              ))
            )
          }
          title="NOTES"
        />
        <ExtendablePanel
          isOpen={extractedPanelActive === ExtractedInfoPanelType.Questions}
          onPanelClick={() => {
            setExtractedPanelActive(
              extractedPanelActive === ExtractedInfoPanelType.Questions
                ? ""
                : ExtractedInfoPanelType.Questions
            );
          }}
          content={
            loading ? (
              <p className="flex w-full justify-center pt-6">
                <Dots />
              </p>
            ) : extractedQuestions.length === 0 ? (
              <p className="w-full p-6 text-center">No questions</p>
            ) : (
              extractedQuestions?.map((item: string, index: number) => (
                <p
                  className="border border-darkBlue-4 py-2 px-4 last:border-0"
                  key={index}
                >
                  {item}
                </p>
              ))
            )
          }
          title="QUESTIONS"
        />
      </div>
      <div className=""></div>
    </div>
  );
};

// - Editable title

const EditableTitle = ({
  uuid,
  title: titleProp,
  visibility,
}: {
  uuid: string;
  title: string;
  visibility: DBTranscriptionVisibility;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [editing, setEditing] = useState(false);
  const [title, setTitle] = useOverridableState(titleProp);

  const queryClient = useQueryClient();

  const { isLoading, mutateAsync } = useMutation<
    TranscriptionUpdateInput,
    { message: "success" }
  >([ApiQueryKeys.TranscriptionUpdate, { uuid }, "PATCH"], {
    onSuccess: () => {
      queryClient.invalidateQueries([ApiQueryKeys.TranscriptionsGet]);
      queryClient.invalidateQueries([ApiQueryKeys.TranscriptionGet, { uuid }]);
    },
  });

  const onFocus = useCallback(() => {
    inputRef.current?.select();
  }, []);

  const onClickEdit = useCallback(() => {
    setEditing(true);
  }, []);

  const onClickSave = useCallback(async () => {
    await mutateAsync({ title });
    setEditing(false);
  }, [mutateAsync, title]);

  const onKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        onClickSave();
      }
    },
    [onClickSave]
  );

  const width = useMemo(() => title.length * 10 + 20, [title]);

  return (
    <div className="flex items-center justify-between">
      <div className="group flex text-lg font-semibold">
        {editing ? (
          <input
            ref={inputRef}
            className={
              "w-full min-w-[140px] rounded-md border border-white bg-blackish3 px-3 py-2 hover:bg-blackish3 sm:w-auto"
            }
            style={{ width: `${width}px` }}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onFocus={onFocus}
            onKeyUp={onKeyUp}
            disabled={isLoading}
            autoFocus
          />
        ) : (
          <p
            className="min-w-[140px] border border-darkBlue-3 px-3 py-2 text-white"
            style={{
              width: `${width}px`,
            }}
          >
            {title}
          </p>
        )}
        <Button
          variant="small"
          wrapperClassName={cx(
            "group-hover:flex ml-2",
            editing ? "flex" : "hidden"
          )}
          onClick={editing ? onClickSave : onClickEdit}
        >
          {editing ? "Save" : "Edit"}
        </Button>
      </div>
      {visibility === DBTranscriptionVisibility.PublicWithLink ? (
        <div title="This transcription is public">
          <GlobeIcon />
        </div>
      ) : null}
    </div>
  );
};

// - View

export const ViewTranscriptionView = ({
  data: {
    uuid,
    title,
    visibility,
    normalizedTranscriptionChunks,
    mediaPlayerType,
    transcriptionMetadata,
  },
  state: { loading, progress },
  computed: { isCompleted },
  actions: { onClickWord },
  user: { isAuthor, name },
}: ViewTranscriptionViewProps) => (
  <div className="mt-4 flex flex-col pb-16 lg:pb-2">
    <div className="flex justify-center">
      {loading && !normalizedTranscriptionChunks.length && (
        <div className="text-center">
          <Dots color="white" />
          <p className="transcription-loader-text mt-10">
            Preparing transcription
          </p>
        </div>
      )}
    </div>

    <div className="flex flex-col xl:flex-row">
      {normalizedTranscriptionChunks.length > 0 && (
        <>
          <div className="m-auto w-full">
            {uuid && isAuthor ? (
              <EditableTitle
                uuid={uuid}
                title={title}
                visibility={visibility}
              />
            ) : (
              <p className="w-full border border-darkBlue-3 px-3 py-2 text-lg font-semibold text-white">
                {title}{" "}
                {name && <span className="text-xs opacity-80">({name})</span>}
              </p>
            )}
            <div className="rounded-md border border-darkBlue-5 bg-darkBlue-2 py-2 px-4">
              <div className="flex items-center justify-between border-b border-blackish2 pt-2 pb-3">
                {loading && typeof progress !== "undefined" ? (
                  <div className="mr-4 flex w-full max-w-[300px]">
                    <Progress
                      withText={progress === 100 ? "Processing..." : true}
                      percent={progress}
                    />
                  </div>
                ) : (
                  <div />
                )}
                <div className="flex items-center">
                  {uuid && isAuthor && (
                    <div className="mr-3 mt-1">
                      <ShareTranscript uuid={uuid} />
                    </div>
                  )}

                  <div>
                    <DownloadTranscript
                      loading={loading}
                      title={title}
                      transcription={normalizedTranscriptionChunks}
                    />
                  </div>
                </div>
              </div>
              <div
                id="transcriptionText"
                className={`${
                  mediaPlayerType === "video" ? "h-[50vh]" : "h-[60vh]"
                } overflow-auto py-2 px-6`}
              >
                {/* {showSpeakerNameEditPopup && (
                        <div className="transcript-item-speaker-edit-popup">
                          <p className="transcript-item-speaker-edit-popup-title">
                            Change speaker name
                          </p>
                          <div className="transcript-item-speaker-edit-popup-form">
                            <div>
                              <input
                                type="text"
                                defaultValue={currentSpeakerName}
                                onChange={(e) => {
                                  setUpdatedSpeakerName(e.target.value);
                                }}
                              />
                            </div>
                            <div>
                              <i
                                className="transcript-item-speaker-edit-popup-icon"
                                onClick={() => setShowSpeakerNameEditPopup(false)}
                              >
                                <CloseIcon />
                              </i>
          
                              <i
                                className="transcript-item-speaker-edit-popup-icon"
                                onClick={() => {
                                  const updatedTranscript = transcriptionResponse.map(
                                    (transcriptItem: any) => {
                                      if (transcriptItem.speaker === currentSpeakerName) {
                                        transcriptItem.speaker = updatedSpeakerName;
                                      }
                                      return transcriptItem;
                                    }
                                  );
          
                                  setTranscriptionResponse(updatedTranscript);
                                  setShowSpeakerNameEditPopup(false);
                                }}
                              >
                                <CheckmarkIcon2 />
                              </i>
                            </div>
                          </div>
                        </div>
                      )} */}

                {normalizedTranscriptionChunks.map(
                  (transcriptionChunk, chunkIndex: number) => (
                    <div
                      className="mt-4 rounded-md leading-6"
                      key={transcriptionChunk.start}
                      id={`chunk_${chunkIndex}`}
                    >
                      {"speaker" in transcriptionChunk &&
                        transcriptionChunk.speaker !== null && (
                          <div
                            className="mb-2 font-bold"
                            onClick={() => {
                              onClickWord(transcriptionChunk.start);
                            }}
                          >
                            Speaker {transcriptionChunk.speaker}:
                          </div>
                        )}
                      {transcriptionChunk.segments.map((segment, index) => (
                        <div
                          className="inline"
                          key={segment.start}
                          onClick={() => {
                            onClickWord(segment.start);
                          }}
                        >
                          <span
                            className="inline cursor-pointer rounded-md hover:underline"
                            id={`segment_${chunkIndex}_${index}`}
                          >
                            {segment.text}
                          </span>
                          <span> </span>
                        </div>
                      ))}
                    </div>
                  )
                )}
                {/* 
                TODO: find the best way to show this error message
                <div className="mt-4 rounded-md leading-6">
                  <div className="my-5 border-t border-redColor pt-5 text-center font-bold text-redColor">
                    Transcription could not be completed
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </>
      )}

      {normalizedTranscriptionChunks.length > 0 &&
        isCompleted &&
        transcriptionMetadata &&
        isAuthor && (
          <ViewExtractedInfo
            transcriptionMetadata={transcriptionMetadata}
            mediaPlayerType={mediaPlayerType}
          />
        )}

      {normalizedTranscriptionChunks.length === 0 && isCompleted && uuid && (
        <div className="m-auto w-full">
          <div className="rounded-md border border-darkBlue-5 bg-darkBlue-2 py-2 px-4 text-center">
            No transcription available
          </div>
        </div>
      )}
    </div>
    <MediaPlayer />
  </div>
);

// - Default export

const ViewTranscription = ({
  state: { loading, progress, transcription },
}: ViewTranscriptionProps) => {
  const { isAuthenticated, user } = useAuth0();
  const { setTime, playVideo, playAudio } = useMediaPlayer();

  // - State

  const normalizedTranscriptionChunks = useMemo(() => {
    if (!transcription?.transcription) {
      return [];
    }

    return "transcriptionWithDiarization" in transcription &&
      transcription.transcriptionWithDiarization.length > 0
      ? transcription.transcriptionWithDiarization
      : transcription.transcription;
  }, [transcription]);

  const mediaPlayerType = useMemo(
    () =>
      transcription?.dbTranscription?.request_type === "file"
        ? MediaPlayerType.Audio
        : MediaPlayerType.Video,
    [transcription?.dbTranscription?.request_type]
  );

  const isAuthor = useMemo(() => {
    if (!isAuthenticated) {
      return false;
    }

    if (!isPublicTranscription(transcription?.dbTranscription)) {
      return true;
    }

    return transcription?.dbTranscription?.user.id === user?.sub;
  }, [isAuthenticated, transcription?.dbTranscription, user?.sub]);

  const name = useMemo(() => {
    if (!isPublicTranscription(transcription?.dbTranscription)) {
      return undefined;
    }

    return transcription?.dbTranscription?.user.email;
  }, [transcription?.dbTranscription]);

  const transcriptionMetadata = useMemo(
    () => transcription?.dbTranscription?.transcription_metadata || {},
    [transcription?.dbTranscription?.transcription_metadata]
  );

  useHighlightTranscript(normalizedTranscriptionChunks);

  // - Actions

  const onClickWord = useCallback(
    (time: number) => {
      setTime(time);
    },
    [setTime]
  );

  // - Trigger Media Player

  useEffect(() => {
    if (
      transcription?.dbTranscription?.request_type ===
        DBTranscriptionRequestType.Youtube &&
      transcription?.dbTranscription?.file_url
    ) {
      playVideo(
        `https://youtube.com/embed/${transcription?.dbTranscription?.file_url}?autoplay=0`
      );
    } else if (
      transcription?.dbTranscription?.request_type ===
        DBTranscriptionRequestType.File &&
      transcription?.dbTranscription?.file_url
    ) {
      playAudio(transcription?.dbTranscription?.file_url);
    }
  }, [
    playAudio,
    playVideo,
    transcription,
    transcription?.dbTranscription?.file_url,
    transcription?.dbTranscription?.request_type,
  ]);

  // - Render

  return (
    <ViewTranscriptionView
      data={{
        uuid: transcription?.uuid,
        title:
          transcription?.dbTranscription?.title ??
          transcription?.created_at ??
          "verbalize_transcription",
        visibility:
          transcription?.dbTranscription?.visibility ??
          DBTranscriptionVisibility.Private,
        normalizedTranscriptionChunks,
        mediaPlayerType,
        transcriptionMetadata,
      }}
      state={{
        loading,
        progress,
      }}
      computed={{
        isCompleted:
          transcription?.dbTranscription?.status ===
          DBTranscriptionStatus.Complete,
      }}
      actions={{
        onClickWord,
      }}
      user={{
        isAuthor,
        name,
      }}
    />
  );
};

export default ViewTranscription;
