import React from "react";

export default function UploadIcon() {
  return (
    <svg
      width="28"
      height="22"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9124 5.97898H22.0124C22.799 5.97911 23.5778 6.13445 24.3043 6.43609C25.0308 6.73772 25.6906 7.17974 26.2459 7.7368C27.3692 8.86263 28 10.388 28 11.9784C28 13.5687 27.3692 15.0941 26.2459 16.2199C25.6906 16.777 25.0308 17.219 24.3043 17.5206C23.5778 17.8223 22.799 17.9776 22.0124 17.9777H18.0228V15.9779H22.0124C23.0422 15.9319 24.0146 15.4904 24.727 14.7453C25.4394 14.0003 25.8371 13.0092 25.8371 11.9784C25.8371 10.9475 25.4394 9.9564 24.727 9.21138C24.0146 8.46635 23.0422 8.02484 22.0124 7.97878H20.1846L19.9386 6.26496C19.7885 5.19122 19.2921 4.1958 18.5248 3.42989C17.7574 2.66399 16.7611 2.16945 15.687 2.0214C14.6124 1.8746 13.5193 2.08443 12.5754 2.6187C11.6314 3.15297 10.8889 3.98217 10.4616 4.97909L9.79566 6.50293L8.17783 6.12497C7.80803 6.03324 7.42892 5.98426 7.04795 5.97898C5.72409 5.97898 4.45422 6.50493 3.52032 7.44283C2.82433 8.14265 2.35077 9.03262 2.15917 10.0008C1.96757 10.969 2.06649 11.9723 2.44349 12.8844C2.82049 13.7966 3.45875 14.5769 4.27801 15.1274C5.09727 15.6778 6.06097 15.9737 7.04795 15.9779H12.0354V17.9777H7.04795C6.05743 17.9866 5.07638 17.7844 4.17017 17.3844C3.26396 16.9844 2.45341 16.3958 1.7925 15.658C0.79322 14.5451 0.176456 13.1416 0.032515 11.6529C-0.111426 10.1642 0.225018 8.66851 0.992586 7.38484C1.50097 6.53437 2.18426 5.80165 2.99721 5.23521C3.81016 4.66877 4.73419 4.28154 5.70809 4.09918C6.67999 3.9192 7.68189 3.9452 8.64379 4.18117C9.2499 2.79335 10.2914 1.64064 11.6109 0.897377C12.9304 0.154117 14.456 -0.139265 15.957 0.0616038C17.4587 0.264805 18.8529 0.952719 19.9278 2.02079C21.0028 3.08885 21.6996 4.47867 21.9124 5.97898ZM18.5867 13.6842L15.959 11.0585V21.9293H13.9712V11.1304L11.4155 13.6862L10.0016 12.2703L14.2932 7.97878H15.709L20.0006 12.2703L18.5867 13.6842V13.6842Z"
        fill="#F8F8F8"
      />
    </svg>
  );
}
