import React from "react";

export default function DocumentDownload() {
  return (
    <svg
      width="16"
      height="19"
      viewBox="0 0 16 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4285 15.0089L14.5195 14.1161L12.8571 15.749V10.5889H11.5714V15.749L9.90899 14.1161L9 15.0089L12.2142 18.1661L15.4285 15.0089Z"
        fill="white"
      />
      <path
        d="M7.71419 16.903H1.2857V1.74844H6.42849V5.53707C6.42951 5.8717 6.56529 6.19233 6.80619 6.42895C7.04708 6.66557 7.37352 6.79895 7.71419 6.79994H11.5713V8.69426H12.857V5.53707C12.8593 5.45408 12.8432 5.3716 12.8099 5.2953C12.7766 5.21899 12.7269 5.15068 12.6641 5.09506L8.16419 0.674998C8.10759 0.613334 8.03805 0.564465 7.96036 0.53176C7.88267 0.499056 7.79868 0.483296 7.71419 0.485566H1.2857C0.945023 0.486566 0.618591 0.619939 0.377696 0.856558C0.136801 1.09318 0.00101746 1.41381 0 1.74844V16.903C0.00101746 17.2376 0.136801 17.5582 0.377696 17.7948C0.618591 18.0315 0.945023 18.1648 1.2857 18.1658H7.71419V16.903ZM7.71419 2.00102L11.3141 5.53707H7.71419V2.00102Z"
        fill="white"
      />
    </svg>
  );
}
