import { Link } from "react-router-dom";

const NotFound = () => (
  <div className="mt-12">
    <h3 className="text-center">404 - Page not found</h3>
    <br />
    <p className="text-center">
      This page doesn&apos;t exist. Why not try going back to the{" "}
      <Link to="/" className="underline hover:no-underline">
        homepage
      </Link>
      ?
    </p>
  </div>
);

export default NotFound;
