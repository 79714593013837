import ReactPlayer from "react-player";
import { BaseReactPlayerProps } from "react-player/base";
import AudioPlayer from "react-h5-audio-player";
import {
  createContext,
  RefObject,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { MediaPlayerType } from "../../api/transcriptions";
import AudioPlayerUI from "../shared/audioPlayer/audioPlayerUI";
import { usePhantomDownload } from "../views/Transcription/DownloadTranscript.utils";

import "../../scss/verbalize.scss";

// - Types

export type MediaPlayerContextType = {
  mediaPlayerType: MediaPlayerType | undefined;
  mediaUrl: string | undefined;
  playVideo: (videoUrl: string) => void;
  playAudio: (audioUrl: string) => void;
  setTime: (timestamp: number) => void;
  reset: () => void;
  // @todo type properly
  events: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    on: (player: MediaPlayerType, event: string, callback: any) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    off: (player: MediaPlayerType, event: string, callback: any) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    trigger: (player: MediaPlayerType, event: string, callback: any) => void;
  };
  refs: {
    videoPlayerRef: RefObject<ReactPlayer>;
    audioPlayerRef: RefObject<AudioPlayer>;
  };
};

export type MediaPlayerProps = object;

// - Context

export const MediaPlayerContext = createContext<MediaPlayerContextType>({
  mediaPlayerType: undefined,
  mediaUrl: undefined,
  playVideo: () => {
    throw new Error("MediaPlayerContext.playVideo() not implemented");
  },
  playAudio: () => {
    throw new Error("MediaPlayerContext.playAudio() not implemented");
  },
  setTime: () => {
    throw new Error("MediaPlayerContext.setTime() not implemented");
  },
  reset: () => {
    throw new Error("MediaPlayerContext.reset() not implemented");
  },
  events: {
    on: () => {
      throw new Error("MediaPlayerContext.on() not implemented");
    },
    off: () => {
      throw new Error("MediaPlayerContext.off() not implemented");
    },
    trigger: () => {
      throw new Error("MediaPlayerContext.trigger() not implemented");
    },
  },
  refs: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    videoPlayerRef: undefined as any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    audioPlayerRef: undefined as any,
  },
});

// - Provider

export const MediaPlayerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // - State

  const [mediaPlayerType, setMediaPlayerType] = useState<
    MediaPlayerType | undefined
  >(undefined);
  const [mediaUrl, setMediaUrl] = useState<string | undefined>(undefined);

  // - Refs

  const videoPlayerRef = useRef<ReactPlayer>(null);
  const audioPlayerRef = useRef<AudioPlayer>(null);

  const callbacksRef = useRef<{
    [key: string]: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: ((data: any) => void)[];
    };
  }>({});

  // - Callbacks

  const reset = useCallback(() => {
    setMediaPlayerType(undefined);
    setMediaUrl(undefined);
  }, []);

  const playVideo = useCallback(
    (videoUrl: string) => {
      reset();
      setMediaPlayerType(MediaPlayerType.Video);
      setMediaUrl(videoUrl);
    },
    [reset]
  );

  const playAudio = useCallback(
    (audioUrl: string) => {
      reset();
      setMediaPlayerType(MediaPlayerType.Audio);
      setMediaUrl(audioUrl);
    },
    [reset]
  );

  const setTime = useCallback(
    (timestamp: number) => {
      if (
        mediaPlayerType === MediaPlayerType.Audio &&
        audioPlayerRef.current &&
        audioPlayerRef.current.audio.current
      ) {
        audioPlayerRef.current.audio.current.currentTime = timestamp;
      } else if (
        mediaPlayerType === MediaPlayerType.Video &&
        videoPlayerRef.current
      ) {
        videoPlayerRef.current.seekTo(timestamp);
        // couldn't figure out how to play the video after seeking
      }
    },
    [mediaPlayerType]
  );

  const on = useCallback(
    (player: MediaPlayerType, event: string, callback: () => void) => {
      if (!callbacksRef.current[player]) {
        callbacksRef.current[player] = {};
      }

      if (!callbacksRef.current[player][event]) {
        callbacksRef.current[player][event] = [];
      }

      callbacksRef.current[player][event].push(callback);
    },
    []
  );

  const off = useCallback(
    (player: MediaPlayerType, event: string, callback: () => void) => {
      if (callbacksRef.current[player] && callbacksRef.current[player][event]) {
        callbacksRef.current[player][event] = callbacksRef.current[player][
          event
        ].filter((cb) => cb !== callback);
      }
    },
    []
  );

  const trigger = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (player: MediaPlayerType, event: string, data: any) => {
      if (callbacksRef.current[player] && callbacksRef.current[player][event]) {
        callbacksRef.current[player][event].forEach((callback) => {
          callback(data);
        });
      }
    },
    []
  );

  // - Value

  const value = useMemo(
    () => ({
      mediaPlayerType,
      mediaUrl,
      playVideo,
      playAudio,
      setTime,
      reset,
      events: {
        on,
        off,
        trigger,
      },
      refs: {
        videoPlayerRef,
        audioPlayerRef,
      },
    }),
    [
      mediaPlayerType,
      mediaUrl,
      off,
      on,
      playAudio,
      playVideo,
      reset,
      setTime,
      trigger,
    ]
  );

  // - Render

  return (
    <MediaPlayerContext.Provider value={value}>
      {children}
    </MediaPlayerContext.Provider>
  );
};

// - Hooks

export const useMediaPlayer = () => useContext(MediaPlayerContext);

// - View

const MediaPlayerVideoView = ({
  url,
  onProgress,
  refObject,
}: {
  url: string;
  onProgress: BaseReactPlayerProps["onProgress"];
  refObject: RefObject<ReactPlayer>;
}) => (
  <div className="fixed right-1 bottom-1 z-[7] mx-auto mt-6 h-24 w-48 rounded-md border border-darkBlue-5 md:h-40 md:w-72 lg:mt-4 lg:h-48 lg:w-80">
    <ReactPlayer
      ref={refObject}
      url={url}
      progressInterval={10}
      onProgress={onProgress}
      controls={true}
      width="100%"
      height="100%"
    />
  </div>
);

const MediaPlayerAudioView = ({
  url,
  onListen,
  onDownload,
  refObject,
}: {
  url: string;
  onListen: (e: { playedSeconds: number }) => void;
  onDownload: () => void;
  refObject: RefObject<AudioPlayer>;
}) => (
  <div className="meeting-notes-audio-player-container">
    {/* <h2 test-id="meetingNotes" className="meeting-notes-source-title">
  {audioTitle}
</h2> */}
    <AudioPlayerUI
      audioPlayerRef={refObject}
      url={url}
      // @todo
      // afterPlayerLoadCallback={initializeTranscriptionFetch}
      recordButton={false}
      audioDownload={onDownload}
      // playerRecordingButtonClick={() => {
      //   if (transcriptionType === "record") {
      //     console.log("press record in player");
      //     setAudioSrc("");
      //     setTranscriptionResponse([]);
      //     setAudioTitle("");
      //     setAudioUrl("");
      //   }
      // }}
      onListen={onListen}
      listenInterval={10}
    />
  </div>
);

// - Player

export const MediaPlayer = (_props: MediaPlayerProps) => {
  const {
    mediaPlayerType,
    mediaUrl,
    refs,
    events: { trigger },
  } = useMediaPlayer();
  const phantomDownload = usePhantomDownload();

  // - Download

  const onDownload = useCallback(() => {
    if (mediaPlayerType !== MediaPlayerType.Audio || !mediaUrl) {
      return;
    }

    const fileName = new URL(mediaUrl).pathname.split("/").pop();
    const fileExt = "ogg"; // @todo

    phantomDownload(`${fileName}.${fileExt}`, mediaUrl, {
      type: "audio/ogg",
      isUrl: true,
    });
  }, [mediaPlayerType, mediaUrl, phantomDownload]);

  // - Render

  if (!mediaPlayerType || !mediaUrl) {
    return null;
  }

  if (mediaPlayerType === MediaPlayerType.Video) {
    return (
      <MediaPlayerVideoView
        url={mediaUrl}
        onProgress={(e) => {
          trigger(MediaPlayerType.Video, "progress", e);
        }}
        refObject={refs.videoPlayerRef}
      />
    );
  }

  if (mediaPlayerType === MediaPlayerType.Audio) {
    return (
      <MediaPlayerAudioView
        url={mediaUrl}
        onListen={(e) => {
          trigger(MediaPlayerType.Audio, "progress", e);
        }}
        onDownload={onDownload}
        refObject={refs.audioPlayerRef}
      />
    );
  }

  return null;
};
