import React from "react";

const PenAndPaperIcon = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9764 12.2359V20.2359H2.06335V6.32289H10.0633L11.8025 4.58376H0.324219V21.9751H17.7155V10.4968L15.9764 12.2359Z"
      fill="#F8F8F8"
    />
    <path
      d="M11.1939 13.5403L8.15039 14.149L8.75909 11.1055L17.3678 2.49681C18.0634 1.80115 19.1069 1.80115 19.8026 2.49681C20.4982 3.19246 20.4982 4.23594 19.8026 4.93159L11.1939 13.5403Z"
      stroke="#F8F8F8"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
);

export default PenAndPaperIcon;
