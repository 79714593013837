const ShareIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="relative top-[1px]"
  >
    <path
      d="M8.5 10H6.5C6.22386 10 6 10.2239 6 10.5V19.5C6 19.7761 6.22386 20 6.5 20H16.5C16.7761 20 17 19.7761 17 19.5V10.5C17 10.2239 16.7761 10 16.5 10H14.5"
      stroke="#fff"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5 14V3M11.5 3L8 6M11.5 3L15 6"
      stroke="#fff"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ShareIcon;
