import classnames from "classnames";

import style from "./Ripple.module.scss";

export default function Ripple({ color = "primary" }) {
  return (
    <div className={classnames(style.rippleLoader, style[color])}>
      <div></div>
      <div></div>
    </div>
  );
}
