import { useCallback, useMemo } from "react";
import { TranscriptionLanguage } from "../../../api/transcriptions";

const LanguageDropdown = ({
  languages,
  setSelectedLanguage,
  selectedLanguage,
}: {
  languages: Record<string, TranscriptionLanguage>;
  setSelectedLanguage: (language: TranscriptionLanguage) => void;
  selectedLanguage: TranscriptionLanguage;
}) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setSelectedLanguage(event.target.value as TranscriptionLanguage);
    },
    [setSelectedLanguage]
  );

  const options = useMemo(
    () =>
      Object.entries(languages).map(([key, value]) => (
        <option key={key} value={value}>
          {key}
        </option>
      )),
    [languages]
  );

  return (
    <select
      value={selectedLanguage}
      onChange={handleChange}
      className="mr-4 rounded-md border border-darkBlue-5 bg-darkBlue-2 p-3 text-sm text-white"
    >
      {options}
    </select>
  );
};

export default LanguageDropdown;
