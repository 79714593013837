import { useQuery } from "@tanstack/react-query";
import { ApiQueryKeys } from "../../../api";
import {
  DBTranscription,
  DBTranscriptionRequestType,
  TranscriptionsGetResults,
} from "../../../api/transcriptions";
import { useCallback, useEffect, useMemo, useRef } from "react";
import cx from "classnames";
import {
  newSearchClient,
  SearchResult,
  WithDocumentId,
} from "../../../lib/search";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../config/routes";
import { getFormattedTime } from "../../../lib/utils";
import HeadphonesIcon from "../../../icons/HeadphonesIcon";
import TelevisionIcon from "../../../icons/TelevisionIcon";
import Button from "../Button";

// - UI Components

const NoResults = ({ query }: { query: string }) => (
  <div className="container mx-auto flex flex-col">
    <div className="mt-2 cursor-pointer rounded-md bg-slate-800 bg-opacity-90 p-6 transition-opacity hover:bg-opacity-100 sm:px-4 sm:py-8">
      <span className="flex items-center justify-center">
        <span className="text-center text-lg font-bold text-slate-200">
          No results for <span className="italic">{query}</span> :(
        </span>
      </span>
    </div>
  </div>
);

const NoTranscriptions = ({
  onClickClose,
}: {
  onClickClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) => (
  <div className="container mx-auto flex flex-col">
    <div className="mt-2 cursor-pointer rounded-md bg-slate-800 bg-opacity-90 p-6 transition-opacity hover:bg-opacity-100 sm:px-4 sm:py-8">
      <span className="flex flex-col items-center justify-center">
        <span className="mb-2 text-center text-lg font-bold text-slate-200">
          You haven&apos;t transcribed anything yet!
        </span>
        <span>
          Why not{" "}
          <Button onClick={onClickClose} className="mx-2 inline-flex">
            record something
          </Button>
          now?
        </span>
      </span>
    </div>
  </div>
);

// - View

const SearchResultsView = ({
  backgroundRef,
  visible,
  query,
  hasTranscriptions,
  results,
  onClickResult,
  onClickClose,
}: {
  backgroundRef: React.Ref<HTMLDivElement>;
  visible: boolean;
  query: string;
  hasTranscriptions: boolean;
  results: SearchResult<WithDocumentId<DBTranscription>>[];
  onClickResult: (id: string) => React.MouseEventHandler<HTMLAnchorElement>;
  onClickClose: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) => (
  <div
    className={cx(
      "fixed left-0 right-0 top-[82px] bottom-0 z-40 h-full w-full bg-black bg-opacity-60 transition-all ease-in-out",
      !visible && "hidden",
      visible && "block"
    )}
    ref={backgroundRef}
  >
    {!hasTranscriptions ? (
      <NoTranscriptions onClickClose={onClickClose} />
    ) : query.length > 2 && results.length === 0 ? (
      <NoResults query={query} />
    ) : (
      <ul className="container mx-auto flex flex-col">
        {results.map((result) => (
          <li
            key={result.id}
            className="mt-2 cursor-pointer rounded-md bg-slate-800 bg-opacity-90 p-6 transition-opacity hover:bg-opacity-100 sm:px-4 sm:py-4"
          >
            <a
              href={`/transcriptions/${result.id}`}
              className="flex flex-col"
              onClick={onClickResult(result.id)}
            >
              <span className="flex w-full items-center">
                <span className="hidden px-2 sm:inline sm:px-8">
                  {result.request_type === DBTranscriptionRequestType.File ? (
                    <HeadphonesIcon />
                  ) : (
                    <TelevisionIcon />
                  )}{" "}
                </span>
                <span className="w-full">
                  <span className="flex justify-between">
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          result._searchMeta?.highlights.title ?? "Voice Note",
                      }}
                      className="text-2xl font-semibold"
                    />
                    <span className="p-2 text-right text-sm text-gray-300 sm:w-1/6">
                      {new Date(result.created_at).toLocaleDateString("en-GB")}
                      <span className="hidden sm:inline">
                        {" "}
                        - {getFormattedTime(new Date(result.created_at))}
                      </span>
                    </span>
                  </span>

                  <span className="flex py-2">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: result._searchMeta?.highlights.text_content,
                      }}
                      className="text-gray-300"
                    />
                  </span>
                </span>
              </span>
            </a>
          </li>
        ))}
      </ul>
    )}
  </div>
);

// - Default export

const SearchResults = ({
  visible,
  query,
  onClickClose: onClickCloseProp,
}: {
  visible: boolean;
  query: string;
  onClickClose: () => void;
}) => {
  const backgroundRef = useRef<HTMLDivElement>(null);

  const transcriptionsQuery = useQuery<TranscriptionsGetResults>([
    ApiQueryKeys.TranscriptionsGet,
    { limit: 1000, offset: 0 },
  ]);

  const searchInstance = useMemo(
    () =>
      newSearchClient(
        transcriptionsQuery.data?.items.map((i) => ({
          ...i,
          id: i.uuid,
        })) ?? [],
        ["text_content", "title", "created_at"],
        {
          highlight: true,
          truncate: ["text_content"],
          truncateLength: 150,
          operator: "AND",
        }
      ),
    [transcriptionsQuery.data?.items]
  );

  const results = useMemo(() => {
    if (!query) return [];

    return searchInstance.search(query);
  }, [query, searchInstance]);

  const hasTranscriptions = useMemo(
    () => (transcriptionsQuery.data?.items ?? []).length > 0 ?? false,
    [transcriptionsQuery.data?.items]
  );

  // - Callbacks

  const navigate = useNavigate();

  const onClickResult = useCallback(
    (uuid: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      onClickCloseProp();
      navigate(routes.transcription(uuid));
    },
    [navigate, onClickCloseProp]
  );

  const onClickClose = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      onClickCloseProp();
      navigate(routes.newTranscriptionRecording);
    },
    [navigate, onClickCloseProp]
  );

  // - Reload on visible change

  useEffect(() => {
    if (visible && transcriptionsQuery.isStale) {
      transcriptionsQuery.refetch();
    }
  }, [visible, transcriptionsQuery]);

  // - Render

  return (
    <SearchResultsView
      backgroundRef={backgroundRef}
      visible={visible}
      query={query}
      hasTranscriptions={hasTranscriptions}
      results={results}
      onClickResult={onClickResult}
      onClickClose={onClickClose}
    />
  );
};

export default SearchResults;
