import { useCallback } from "react";
import { FileUploader } from "react-drag-drop-files";
import { UseMutationResult } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  DBTranscription,
  TranscriptionCreateInput,
} from "../../../../api/transcriptions";
import { routes } from "../../../../config/routes";
import UploadIcon from "../../../../icons/UploadIcon";
import { readFileAsync } from "../../../../lib/utils";
import { useError } from "../../../providers/ErrorProvider";
import { useLanguage } from "../../../providers/LanguageProvider";

// - Types

export interface FileUploadViewProps {
  callbacks: {
    onFileUploadChange: (targetFile: File) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onFileUploadTypeError: (error: any) => void;
  };
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type FileUploadProps = {};

// - View

export const FileUploadView = ({
  callbacks: { onFileUploadChange, onFileUploadTypeError },
}: FileUploadViewProps) => (
  <>
    <div className="my-4 w-full text-center text-xl font-thin">
      Please upload a file to get started
    </div>

    <div className="mt-4 w-full">
      <FileUploader
        handleChange={onFileUploadChange}
        name="file"
        types={["MP3", "MP4", "OGG", "WAV", "M4A"]}
        classes="drop-zone"
        onTypeError={onFileUploadTypeError}
      >
        <div className="flex w-full cursor-pointer flex-col rounded-md border border-dashed bg-gray-900 p-4 py-20 text-center outline-none hover:opacity-80">
          <p className="mx-auto flex">
            <span className="mr-2">Upload audio/video file</span>
            <span>
              <UploadIcon />
            </span>
          </p>
          <p className="mt-2 text-xs italic text-gray-400">Max 100MB/2 hours</p>
        </div>
      </FileUploader>
    </div>
  </>
);

// - Default export

const FileUpload = ({
  createTranscriptionMutation,
}: {
  createTranscriptionMutation: UseMutationResult<
    DBTranscription,
    unknown,
    TranscriptionCreateInput,
    unknown
  >;
}) => {
  const [, setError] = useError();
  const navigate = useNavigate();
  const { language } = useLanguage();

  // - File upload callbacks

  const onFileUploadChange = useCallback(
    async (targetFile: File) => {
      if (!targetFile) {
        return;
      }

      const file = await readFileAsync(targetFile);

      const currentTime = new Date();

      const title =
        targetFile.name ||
        `transcript_${
          currentTime.getFullYear() +
          "-" +
          (currentTime.getMonth() + 1) +
          "-" +
          currentTime.getDate() +
          currentTime.getHours() +
          currentTime.getMinutes() +
          currentTime.getSeconds()
        }`;

      const result = await createTranscriptionMutation.mutateAsync({
        title,
        language,
        word_timestamps: true,
        diarize: true,
        file,
      });

      navigate(routes.transcribe(result.uuid));
    },
    [createTranscriptionMutation, language, navigate]
  );

  const onFileUploadTypeError = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (err: any) => {
      console.error("error uploading file. ", err);
      setError(
        "Unable to upload file. Please ensure the file is of the correct type (MP3, MP4, OGG, WAV)."
      );
    },
    [setError]
  );

  // - Render

  return (
    <FileUploadView
      callbacks={{
        onFileUploadChange,
        onFileUploadTypeError,
      }}
    />
  );
};

export default FileUpload;
