export const completionFetchURL = `https://${
  import.meta.env.VITE_APP_HOST || window.location.host + "/api/v1"
}/inference/completion`;

export const imageGenerationFetchURL = `https://${
  import.meta.env.VITE_APP_HOST || window.location.host + "/api/v1"
}/stable-diffusion/generate`;

export const middlewareHost = import.meta.env.VITE_MIDDLEWARE_URL;

export const lemmaUrl = `https://${
  import.meta.env.VITE_APP_ENV === "production"
    ? "lemm.ai"
    : "staging.products-web-ui.pages.dev"
}`; //TODO: review if we want to use lemma or build subscription page in the app

const verbalizeUrl = `https://${
  import.meta.env.VITE_APP_HOST || window.location.host
}`;

export const subscribeUrl = `${verbalizeUrl}/subscriptions`; // we dont need it if we use standalone app
