const VerbalizeLogo = ({
  color = "#fff",
  size = 25,
}: {
  color?: string;
  size?: number;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1641_176)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1571 14.1375C14.0117 16.1003 15.1209 15.5198 15.426 14.0502C16.7029 10.2043 17.9128 6.08048 19.3154 2.31289C20.9133 -1.97934 25.8091 0.380708 24.8849 3.90078C23.2091 8.97112 21.5194 14.0372 19.8379 19.1058C19.3999 20.4261 18.6698 23.2238 17.9323 24.0835C17.417 24.6843 16.1522 25.1717 15.2284 24.9421C14.4002 24.7362 13.7359 24.314 13.2964 23.4078C12.6337 22.0413 13.2998 15.9916 13.5879 12.8369C13.7185 11.4066 13.1682 10.246 12.5114 12.225C12.1859 13.2058 11.9308 13.9961 11.6261 14.8849C10.4558 18.2987 6.69711 18.1879 5.99918 14.7635C5.55292 12.5744 5.85121 9.44788 5.07 9.25093C4.60444 9.04413 3.80065 9.66552 2.86674 9.79494C0.536782 10.1177 -1.18402 6.57184 1.01695 4.61468C2.1927 3.56923 4.08848 3.40105 5.22248 5.23417C5.65405 6.3649 5.89575 8.24814 6.13841 9.78519C6.38259 11.3318 7.10708 10.2928 7.42872 9.35932L9.25394 4.0623C9.58379 3.10508 10.2636 2.35499 11.3761 2.11299C13.3106 1.69231 15.0011 3.24829 14.8074 5.57566C14.5705 8.42107 14.3685 11.2875 14.1571 14.1375Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_1641_176">
        <rect width={size} height={size} fill={color} />
      </clipPath>
    </defs>
  </svg>
);

export default VerbalizeLogo;
