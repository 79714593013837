import { Link } from "react-router-dom";

import { routes } from "../../../config/routes";

const Footer = () => (
  <footer className="fixed bottom-0 z-[4] w-full bg-black py-2 text-center text-xs text-gray-200">
    <span className="mr-1">
      <Link className="underline hover:no-underline" to={routes.legal}>
        Legal
      </Link>
    </span>
    &middot;
    <span className="ml-1">
      Built with ❤️ by{" "}
      <a
        href="https://www.conjecture.dev/"
        className="underline hover:no-underline"
      >
        Conjecture.dev
      </a>
    </span>
  </footer>
);

export default Footer;
