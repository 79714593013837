import { useCallback, useEffect, useRef } from "react";
import { TranscriptionChunk, MediaPlayerType } from "../../api/transcriptions";
import { useMediaPlayer } from "../../components/providers/MediaPlayerProvider";
import { delay } from "../utils";

export const useHighlightTranscript = (
  transcriptionChunks: TranscriptionChunk[]
) => {
  // - Highlighting logic

  const scrollingRef = useRef(false);

  const highlightCurrentWord = useCallback(
    async (currentTime: number) => {
      // We add 0.001 seconds to the current time to make sure it doesn't lie on the border of two words
      const highlightTime = currentTime + 0.001;

      transcriptionChunks.forEach((chunk, chunk_index: number) => {
        const speaker =
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          chunk.speaker !== null
            ? // @ts-expect-error TS doesn't know that speaker is set by now
              chunk.speaker + 1
            : 1;

        // If we're within the current chunk, we want to highlight it
        if (chunk?.start <= highlightTime && chunk?.end >= highlightTime) {
          document
            .getElementById(`chunk_${chunk_index}`)
            ?.classList.add(`highlight-section-${speaker}`);

          if (!scrollingRef.current) {
            scrollingRef.current = true;

            document.getElementById(`chunk_${chunk_index}`)?.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center",
            });

            delay(500).then(() => {
              scrollingRef.current = false;
            }); // here we wait 0.5 seconds before we can scroll again, otherwise we interrupt the smooth scroll
          }
        } else {
          // Otherwise, we want to remove the highlight
          document
            .getElementById(`chunk_${chunk_index}`)
            ?.classList.remove(`highlight-section-${speaker}`);
        }

        if (chunk?.segments) {
          chunk.segments.forEach((segment, index) => {
            if (
              segment?.start <= highlightTime &&
              segment?.end >= highlightTime
            ) {
              document
                .getElementById(`segment_${chunk_index}_${index}`)
                ?.classList.add(`highlight-${speaker}`);
            } else {
              document
                .getElementById(`segment_${chunk_index}_${index}`)
                ?.classList.remove(`highlight-${speaker}`);
            }
          });
        }
      });
    },
    [transcriptionChunks]
  );

  // - Media player logic

  const onVideoProgress = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      const currentTime = event.playedSeconds || -1;
      highlightCurrentWord(currentTime);
    },
    [highlightCurrentWord]
  );

  const onAudioProgress = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      const currentTime = event.srcElement?.currentTime || -1;
      highlightCurrentWord(currentTime);
    },
    [highlightCurrentWord]
  );

  // - Register events with the media player

  const {
    events: { on, off },
  } = useMediaPlayer();

  useEffect(() => {
    on(MediaPlayerType.Video, "progress", onVideoProgress);
    on(MediaPlayerType.Audio, "progress", onAudioProgress);

    return () => {
      off(MediaPlayerType.Video, "progress", onVideoProgress);
      off(MediaPlayerType.Audio, "progress", onAudioProgress);
    };
  }, [on, off, onVideoProgress, onAudioProgress]);
};

export default useHighlightTranscript;
