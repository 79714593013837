import { TranscriptionLanguage } from "../api/transcriptions";

export const webRegex =
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

export const youtubeRegex =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;

export const audioFileRegex = /\.(mp3|mp4|ogg|wav)$/i;

export const delay = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getFormattedDate = () => {
  const today = new Date();

  const formattedToday =
    today.toLocaleString("default", { month: "long" }) +
    " " +
    today.getDate() +
    ", " +
    today.getFullYear();

  return formattedToday;
};

export const getFormattedTime = (date: Date) => {
  const minutes =
    date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes();

  const time = date.getHours() + ":" + minutes;

  return time;
};

export const getFormattedCurrentTime = () => getFormattedTime(new Date());

export function objectToArr(array: any[]) {
  let resultArr = [];

  for (const item in array) {
    resultArr.push({ id: item, ...array[item] });
  }

  return resultArr;
}

export function shuffleArray(array: any[]) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

export function findPath(tree: any, target: string): any {
  // The value of this node
  let currentId = tree.id;
  if (currentId === target) return [target];
  for (let t of Object.entries(tree)) {
    // Search children
    if (t[0] === "children" && t[1]) {
      for (let child of t[1] as any) {
        let found = findPath(child, target);
        if (found) {
          return [currentId].concat(found);
        }
      }
    }
  }
  // Not found in this branch
  return null;
}

export function getPathNodes(tree: any, target: string, key: string): any {
  // The value of this node
  let currentId = tree.id;
  if (currentId === target) return [{ id: currentId, [key]: tree[key] }];
  for (let t of Object.entries(tree)) {
    // Search children
    if (t[0] === "children" && t[1]) {
      for (let child of t[1] as any) {
        let found = getPathNodes(child, target, key);
        if (found) {
          return [{ id: currentId, [key]: tree[key] }].concat(found);
        }
      }
    }
  }
  // Not found in this branch
  return null;
}

export function getNodeObject(array: any[] = [], id: string) {
  let node;
  array.some(
    (object) =>
      (node = object.id === id ? object : getNodeObject(object.children, id))
  );
  return node;
}

export function editTree(
  arr: any[],
  id: string,
  key: string,
  value: string
): any[] {
  return arr?.map((item) => {
    if (key === "children" && item.id === id) {
      return {
        ...item,
        children: item?.children?.length ? item?.children.concat(value) : value,
      };
    }
    return {
      ...item,
      [key]: item.id === id ? value : item[key],
      children: editTree(item.children, id, key, value),
    };
  });
}
export function deleteItemInTree(arr: any[], id: string): any[] {
  return arr
    ?.map((item) => {
      if (item.id === id) {
        return undefined;
      }
      return {
        ...item,
        children: deleteItemInTree(item.children, id),
      };
    })
    .filter((filterItem) => filterItem && filterItem);
}

export function setCrossSubdomainCookie(
  name: string,
  value: string,
  days: number
) {
  const assign = name + "=" + escape(value) + ";";
  const d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString() + ";";
  const domain = "domain=lemm.ai;";
  const path = "path=/;";
  document.cookie = assign + expires + path + domain + "SameSite=None; Secure";
}

export function combineArrayItemPropToText(nodePath: any[], key: string) {
  if (!nodePath?.length) {
    return "Wrong prompt";
  }
  return nodePath.map((nodeItem) => nodeItem[key]).join();
}

export function convertHMS(
  seconds: number,
  includeMilliseconds: boolean = false
): string {
  const date = new Date(seconds * 1000);
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const secs = date.getUTCSeconds().toString().padStart(2, "0");
  if (includeMilliseconds) {
    const milliseconds = date.getUTCMilliseconds().toString().padStart(3, "0");
    return `${hours}:${minutes}:${secs},${milliseconds}`;
  } else {
    return `${hours}:${minutes}:${secs}`;
  }
}

export function validateUrl(value: string, regexValue: RegExp) {
  return regexValue.test(value);
}

export function readFile(input: any, callback: any) {
  let file = input;

  let reader = new FileReader();

  let audioSrc;

  reader.readAsDataURL(file);

  reader.onload = () => {
    let resultReader = reader?.result || "";
    let resultAsString;

    if (resultReader) {
      resultAsString = resultReader.toString();
    }

    const resultArr = resultAsString?.split(",");

    if (resultArr?.length) {
      // setAudioSrc(resultArr[1]);
      audioSrc = resultArr[1];
      callback(audioSrc);
    }
  };

  reader.onerror = function () {
    console.log(reader.error);
  };

  return audioSrc;
}

export const supportedLanguages: Record<string, TranscriptionLanguage> = {
  English: TranscriptionLanguage.English,
  Spanish: TranscriptionLanguage.Spanish,
  Portuguese: TranscriptionLanguage.Portuguese,
  Russian: TranscriptionLanguage.Russian,
  German: TranscriptionLanguage.German,
  Japanese: TranscriptionLanguage.Japanese,
  Polish: TranscriptionLanguage.Polish,
  Dutch: TranscriptionLanguage.Dutch,
  Indonesian: TranscriptionLanguage.Indonesian,
  Catalan: TranscriptionLanguage.Catalan,
  French: TranscriptionLanguage.French,
  Turkish: TranscriptionLanguage.Turkish,
  Swedish: TranscriptionLanguage.Swedish,
  Ukrainian: TranscriptionLanguage.Ukrainian,
  Malay: TranscriptionLanguage.Malay,
  Norwegian: TranscriptionLanguage.Norwegian,
  Finnish: TranscriptionLanguage.Finnish,
  Italian: TranscriptionLanguage.Italian,
};

export const readFileAsync = (file: Blob) => {
  const reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    reader.onload = () => {
      const resultReader = reader?.result || "";

      if (resultReader) {
        const resultAsString = resultReader.toString();
        const resultArr = resultAsString?.split(",");

        if (resultArr?.length) {
          resolve(resultArr[1]);
        }
      }
    };

    reader.readAsDataURL(file);

    reader.onerror = () => {
      reject(reader.error);
    };
  });
};
