// - General

export enum Product {
  MeetingNotes = "meeting_notes",
}

// - Query Results

export type SubscriptionGetResult = {
  subscription: Product[];
};

export type FreeUsageGetResult = {
  free_usage: number;
};

export type CheckoutSessionCreateResult = {
  url: string;
};

export type PortalSessionCreateResult = {
  url: string;
};

// Mutations

export type CheckoutSessionCreateInput = {
  lookup_key: "meeting_notes";
};
