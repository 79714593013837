// - View

import { useCallback, useEffect, useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ApiQueryKeys, QueryResults } from "../../api";
import {
  DBTranscriptionStatus,
  TranscriptionWithMetadata,
} from "../../api/transcriptions";
import ViewTranscription from "../../components/views/Transcription/ViewTranscription";
import { routes } from "../../config/routes";
import useStreamTranscription from "../../lib/hooks/useStreamTranscription";
import { useError } from "../../components/providers/ErrorProvider";

const StreamTranscriptionPageView = ViewTranscription;

// - Default export

const StreamTranscriptionPage = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [, onError] = useError();

  // - Query transcription

  const dbTranscription = useQuery<QueryResults[ApiQueryKeys.TranscriptionGet]>(
    [
      ApiQueryKeys.TranscriptionGet,
      {
        uuid,
      },
    ]
  );

  // - Streaming logic

  const queryClient = useQueryClient();

  const onComplete = useCallback(
    async (transcription: TranscriptionWithMetadata) => {
      await queryClient.invalidateQueries({
        queryKey: [ApiQueryKeys.TranscriptionsGet],
      });
      navigate(routes.transcription(transcription.uuid));
    },
    [navigate, queryClient]
  );

  const {
    streamTranscription,
    transcription: streamedTranscription,
    diarizedTranscription,
    loading: streamLoading,
    progress,
  } = useStreamTranscription({
    onComplete,
    onError,
  });

  // - Stream on load

  useEffect(() => {
    (async () => {
      if (
        !uuid ||
        !dbTranscription.data ||
        [DBTranscriptionStatus.Complete, DBTranscriptionStatus.Error].includes(
          dbTranscription.data.status
        ) ||
        dbTranscription.data?.status !== DBTranscriptionStatus.Pending ||
        streamLoading
      ) {
        return;
      }

      await streamTranscription(uuid);
    })();
  }, [uuid, streamTranscription, dbTranscription.data, streamLoading]);

  // - Redirect if we've already got a transcription

  useEffect(() => {
    if (
      dbTranscription.data &&
      uuid &&
      ![
        DBTranscriptionStatus.Pending,
        DBTranscriptionStatus.Incomplete,
      ].includes(dbTranscription.data?.status)
    ) {
      navigate(routes.transcription(uuid));
    }
  }, [dbTranscription.data, navigate, uuid]);

  // - View prep

  const loading = useMemo(
    () => streamLoading || dbTranscription.isLoading,
    [streamLoading, dbTranscription.isLoading]
  );

  const transcriptionWithoutDbTranscription = useMemo(
    () => diarizedTranscription || streamedTranscription,
    [diarizedTranscription, streamedTranscription]
  );

  const transcription = useMemo(
    () =>
      ({
        ...transcriptionWithoutDbTranscription,
        dbTranscription: dbTranscription.data,
      } as TranscriptionWithMetadata),
    [dbTranscription.data, transcriptionWithoutDbTranscription]
  );

  // - Render

  return (
    <StreamTranscriptionPageView
      state={{
        loading,
        progress,
        transcription,
      }}
    />
  );
};

export default StreamTranscriptionPage;
