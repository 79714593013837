import { useEffect, useMemo, useState } from "react";

const useOverridableState = <T>(initialValue: T) => {
  const [state, setState] = useState<T>(initialValue);

  useEffect(() => {
    setState(initialValue);
  }, [initialValue, setState]);

  return useMemo(() => [state, setState] as const, [state, setState]);
};

export default useOverridableState;
