import React from "react";

export default function Upload2Icon({ color = "#F8F8F8" }: any) {
  return (
    <svg
      width="23"
      height="25"
      viewBox="0 0 23 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5427 4.50624C11.4128 4.3763 11.2308 4.29834 11.0489 4.29834C10.8618 4.29834 10.6851 4.3711 10.5552 4.50624L5.99176 9.06965C5.71629 9.34512 5.71629 9.78691 5.99176 10.0624C6.26722 10.3378 6.70901 10.3378 6.98448 10.0624L10.3473 6.69959V24.2983C10.3473 24.6882 10.6591 25 11.0489 25C11.4387 25 11.7506 24.6882 11.7506 24.2983V6.69959L15.1134 10.0624C15.2485 10.1975 15.4304 10.2703 15.6071 10.2703C15.7891 10.2703 15.9658 10.2027 16.1009 10.0624C16.3764 9.78691 16.3764 9.34512 16.1009 9.06965L11.5427 4.50624Z"
        fill={color}
      />
      <path
        d="M21.3981 0H0.701663C0.31185 0 0 0.31185 0 0.701663V5.86279C0 6.2526 0.31185 6.56445 0.701663 6.56445C1.09148 6.56445 1.40333 6.2526 1.40333 5.86279V1.40333H20.7017V5.86279C20.7017 6.2526 21.0135 6.56445 21.4033 6.56445C21.7931 6.56445 22.105 6.2526 22.105 5.86279V0.701663C22.0998 0.31185 21.7879 0 21.3981 0Z"
        fill={color}
      />
    </svg>
  );
}
